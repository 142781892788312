// Модуль диспетчер для отрисовки баннерров на конструкторе
import WeatherWidget from './weather-widget';
import GeneratorWidget from './generator-widget'
const Promise = require('es6-promise').Promise;
require('String.fromCodePoint');

//Рисуем DOM дерево

const generateWidget = new GeneratorWidget();
// для совместимости с ранней версией библиотеки проверяем
// на то объект ли он myWidgetParam или массив объектов
if (!window.myWidgetParam.length) {
  const arr = [];
  arr.push(window.myWidgetParam);
  window.myWidgetParam = arr;
}
window.myWidgetParam.forEach((myWidgetParam) => {
  var container = document.getElementById(myWidgetParam.containerid);
  // Определяем не отрисован ли виджет уже
  if (!(container.textContent).trim()) {
    // generateWidget.render(myWidgetParam);
    const documentContain = generateWidget.getDocumentFragment(myWidgetParam);
    const documentFragmentWidget = documentContain.documentFragment;
    const containerLoader = documentContain.containerLoader;
    let q = '';
    // Формируем параметр фильтра по городу
    if (myWidgetParam.cityid) {
      q = `?id=${myWidgetParam.cityid}`;
    } else if (myWidgetParam.city_name) {
      q = `?q=${myWidgetParam.city_name}`;
    } else {
      q = '?q=London';
    }

    // Установка цвета кривых для графика
    let colorPolilyne = "#000";
    if(generateWidget.selDOMElement.colorPolilyne) {
      colorPolilyne = generateWidget.selDOMElement.colorPolilyne;
    }
    let unitsTemp;
    if (myWidgetParam.units) {
      if(myWidgetParam.units === 'imperial') {
        unitsTemp = ['imperial', '°F', 'm/h'];
      } else {
        unitsTemp = ['metric', '°C', 'm/s'];
      }
    } else {
      unitsTemp = ['metric', '°C', 'm/s'];
    }

    const schema = myWidgetParam.schema ? myWidgetParam.schema : 'default';

    const getProtocol = function() {
      if (document.location.protocol === 'https:') {
        return 'https:';
      } else {
        return 'http:';
      }
    };
    const hostName = document.location.hostname === 'openweathermap.phase.owm.io'
      ? 'openweathermap.phase.owm.io'
      : 'openweathermap.org';
    const paramsWidget = {
      id: myWidgetParam.id,
      cityName: 'Moscow',
      lang: 'en',
      appid: myWidgetParam.appid,
      units: unitsTemp[0],
      textUnitTemp: unitsTemp[1],
      textUnitWind: unitsTemp[2],
      colorPolilyne: colorPolilyne,
      baseURL: generateWidget.baseURL,
      urlDomain: schema === 'default' ? `//api.${hostName}` : `//${hostName}` ,
    };

    // const containerWidget = document.getElementById(myWidgetParam.containerid) || document;
    const controlsWidget = {
      // Первая половина виджетов
      cityName: documentFragmentWidget.querySelectorAll('.widget-left-menu__header'),
      temperature: documentFragmentWidget.querySelectorAll('.weather-left-card__number'),
      naturalPhenomenon: documentFragmentWidget.querySelectorAll('.weather-left-card__means'),
      windSpeed: documentFragmentWidget.querySelectorAll('.weather-left-card__wind'),
      mainIconWeather: documentFragmentWidget.querySelectorAll('.weather-left-card__img'),
      calendar: documentFragmentWidget.querySelector('.widget-left__calendar'),
      calendarItem: documentFragmentWidget.querySelectorAll('.calendar__item'),
      // Вторая половина виджетов
      cityName2: documentFragmentWidget.querySelectorAll('.widget-right__title'),
      temperature2: documentFragmentWidget.querySelectorAll('.weather-right__temperature'),
      temperatureFeels: documentFragmentWidget.querySelectorAll('.weather-right__feels'),
      temperatureMin: documentFragmentWidget.querySelectorAll('.weather-right-card__temperature-min'),
      temperatureMax: documentFragmentWidget.querySelectorAll('.weather-right-card__temperature-max'),
      naturalPhenomenon2: documentFragmentWidget.querySelectorAll('.widget-right__description'),
      windSpeed2: documentFragmentWidget.querySelectorAll('.weather-right__wind-speed'),
      mainIconWeather2: documentFragmentWidget.querySelectorAll('.weather-right__icon'),
      humidity: documentFragmentWidget.querySelectorAll('.weather-right__humidity'),
      pressure: documentFragmentWidget.querySelectorAll('.weather-right__pressure'),
      dateReport: documentFragmentWidget.querySelectorAll(".widget-right__date"),
      documentFragmentWidget,
      containerWidget: document.getElementById(myWidgetParam.containerid),
    };
    // доопределям для прорисовки графиков
    const graphic = documentFragmentWidget.querySelector('#graphic');
    const graphic1 = documentFragmentWidget.querySelector('#graphic1');
    const graphic2 = documentFragmentWidget.querySelector('#graphic2');
    const graphic3 = documentFragmentWidget.querySelector('#graphic3');
    if (graphic) {
      controlsWidget.graphic = graphic;
    }
    if (graphic1) {
      controlsWidget.graphic = graphic1;
    }
    if (graphic2) {
      controlsWidget.graphic = graphic2;
    }
    if (graphic3) {
      controlsWidget.graphic = graphic3;
    }

    const urls = {
      urlWeatherAPI: `${paramsWidget.urlDomain}/data/2.5/widgets/weather${q}&units=${paramsWidget.units}&appid=${paramsWidget.appid}&callback=?`,
      paramsUrlForeDaily: `${paramsWidget.urlDomain}/data/2.5/widgets/forecast${q}&units=${paramsWidget.units}&cnt=8&appid=${paramsWidget.appid}&callback=?`,
      windSpeed: `${paramsWidget.baseURL}/data/wind-speed-data.json`,
      windDirection: `${paramsWidget.baseURL}/data/wind-direction-data.json`,
      clouds: `${paramsWidget.baseURL}data/clouds-data.json`,
      naturalPhenomenon: `${paramsWidget.baseURL}/data/natural-phenomenon-data.json`,
    };

    controlsWidget.containerWidget.innerText = '';
    controlsWidget.containerWidget.appendChild(containerLoader);

    // return;
    const objWidget = new WeatherWidget(paramsWidget, controlsWidget, urls);
    if (controlsWidget.cityName.length || controlsWidget.cityName2.length) {
      objWidget.render();
    }
  }
});
