/**
 * Created by Denis on 13.10.2016.
 */
export default class GeneratorWidget {
  constructor() {
    this.baseDomain = document.location.hostname === 'openweathermap.phase.owm.io' ?
      'openweathermap.phase.owm.io' : 'openweathermap.org';
    // this.baseDomain = 'localhost:3000';
    this.baseURL = `//${this.baseDomain}/themes/openweathermap/assets/vendor/owm`;
    this.scriptD3SRC = `${this.baseURL}/js/libs/d3.min.js`;
    this.scriptSRC = `${this.baseURL}/js/weather-widget-generator-2.0.js`;
    // объект-карта для сопоставления всех виджетов с кнопкой-инициатором их вызова для генерации кода
    this.mapWidgets = {
      'widget-1-left-blue': {
        code: `<script src="${this.scriptD3SRC}"></script>
                       ${this.getCodeForGenerateWidget(2, 524901, 'Moscow', '')}`,
        schema: 'blue',
      },
      'widget-2-left-blue': {
        code: `<script type="text/javascript">
                            window.myWidgetParam = {
                                id: 2,
                                cityid: 524901,
                                containerid: 'openweathermap-widget',
                            };
                            (function() {
                                var script = document.createElement('script');
                                script.type = 'text/javascript';
                                script.async = true;
                                script.src = ${this.scriptSRC};
                                var s = document.getElementsByTagName('script')[0];
                                s.parentNode.insertBefore(script, s);
                            })();
                      </script>`,
        schema: 'blue',
      },
      'widget-left-blue': {
        code: 'script.js?type=left&schema=blue&id=3',
        schema: 'blue',
      },
      'widget-4-left-blue': {
        code: 'script.js?type=left&schema=blue&id=4',
        schema: 'blue',
      },
      'widget-5-right-blue': {
        code: 'script.js?type=right&schema=blue&id=5',
        schema: 'blue',
      },
      'widget-6-right-blue': {
        code: 'script.js?type=right&schema=blue&id=6',
        schema: 'blue',
      },
      'widget-7-right-blue': {
        code: 'script.js?type=right&schema=blue&id=7',
        schema: 'blue',
      },
      'widget-8-right-blue': {
        code: 'script.js?type=right&schema=blue&id=8',
        schema: 'blue',
      },
      'widget-9-right-blue': {
        code: 'script.js?type=right&schema=blue&id=9',
        schema: 'blue',
      },
      'widget-1-left-brown': {
        code: `<script src="${this.scriptD3SRC}"></script>
                            <script type="text/javascript">
                                    window.myWidgetParam = {
                                    id: 10,
                                    cityid: 524901,
                                    containerid: 'openweathermap-widget',
                                };
                                (function() {
                                    var script = document.createElement('script');
                                    script.type = 'text/javascript';
                                    script.async = true;
                                    script.src = ${this.scriptSRC};
                                    var s = document.getElementsByTagName('script')[0];
                                    s.parentNode.insertBefore(script, s);
                                })();
                            </script>`,
        schema: 'brown',
      },
      'widget-2-left-brown': {
        code: `<script type="text/javascript">
                            window.myWidgetParam = {
                                id: 11,
                                cityid: 524901,
                                containerid: 'openweathermap-widget',
                            };
                            (function() {
                                var script = document.createElement('script');
                                script.type = 'text/javascript';
                                script.async = true;
                                script.src = ${this.scriptSRC};
                                var s = document.getElementsByTagName('script')[0];
                                s.parentNode.insertBefore(script, s);
                            })();
                        </script>`,
        schema: 'brown',
      },
      'widget-left-brown': {
        code: `<script type="text/javascript">
                            window.myWidgetParam = {
                                id: 12,
                                cityid: 524901,
                                containerid: 'openweathermap-widget',
                            };
                            (function() {
                                var script = document.createElement('script');
                                script.type = 'text/javascript';
                                script.async = true;
                                script.src = ${this.scriptSRC};
                                var s = document.getElementsByTagName('script')[0];
                                s.parentNode.insertBefore(script, s);
                            })();
                        </script>`,
        schema: 'brown',
      },
      'widget-4-left-brown': {
        code: 'script.js?type=left&schema=brown&id=4',
        schema: 'brown',
      },
      'widget-5-right-brown': {
        code: 'script.js?type=left&schema=brown&id=5',
        schema: 'brown',
      },
      'widget-6-right-brown': {
        code: 'script.js?type=left&schema=brown&id=6',
        schema: 'brown',
      },
      'widget-7-right-brown': {
        code: 'script.js?type=right&schema=brown&id=7',
        schema: 'brown',
      },
      'widget-8-right-brown': {
        code: 'script.js?type=right&schema=brown&id=8',
        schema: 'brown',
      },
      'widget-9-right-brown': {
        code: 'script.js?type=right&schema=brown&id=9',
        schema: 'brown',
      },
      'widget-1-left-white': {
        code: 'script.js?type=left&schema=white&id=1',
        schema: 'none',
      },
      'widget-2-left-white': {
        code: 'script.js?type=left&schema=white&id=2',
        schema: 'none',
      },
      'widget-left-white': {
        code: 'script.js?type=left&schema=white&id=3',
        schema: 'none',
      },
      'widget-4-left-white': {
        code: 'script.js?type=left&schema=white&id=4',
        schema: 'none',
      },
    }
    this.domElements = {
      id1: {
        id: 1,
        type: 'left',
        schema: 'blue',
        colorPolilyne: '#DDF730',
        domEmpty: `<div class="widget-left widget-left--blue">
                     <i class="widget-left__loading" />
                   </div>`,
        dom: `
                    <div class="widget-left widget-left--blue">
                    <div class="widget-left-menu widget-left-menu--blue">
                      <div class="widget-left-menu__layout">
                        <h2 class="widget-left-menu__header"></h2>
                        <a href="//openweathermap.org/" target="_blank"><div class="widget-left-menu__logo_black"></div></a>
                      </div>
                    </div>
                    <div class="widget-left__body">
                      <div class="weather-left-card">
                        <div class="weather-left-card__row1"><img src="" width="128" height="128" alt="" class="weather-left-card__img"/>
                          <div class="weather-left-card__col">
                            <p class="weather-left-card__number"><sup class="weather-left-card__degree"></sup></p><span class="weather-left-card__rising">and rising</span>
                          </div>
                        </div>
                        <div class="weather-left-card__row2">
                          <p class="weather-left-card__means">-</p>
                          <p class="weather-left-card__wind">Wind:</p>
                        </div>
                      </div>
                      <div class="widget-left__calendar">
                        <ul class="calendar">
                          <li class="calendar__item">Today</li>
                          <li class="calendar__item"></li>
                          <li class="calendar__item"></li>
                          <li class="calendar__item"></li>
                          <li class="calendar__item"></li>
                          <li class="calendar__item"></li>
                          <li class="calendar__item"></li>
                          <li class="calendar__item"></li>
                        </ul>
                        <div id="graphic1" class="widget-left__graphic"></div>
                      </div>
                    </div>
                  </div>`,
      },
      id2: {
        id: 2,
        type: 'left',
        schema: 'blue',
        domEmpty: `<div class="widget-left widget-left--compact widget-left--blue">
                        <i class="widget-left__loading" />
                    </div>`,
        dom: `<div class="widget-left widget-left--compact widget-left--blue">
                <div class="widget-left-menu widget-left-menu--compact widget-left-menu--blue">
                    <div class="widget-left-menu__layout widget-left-menu__layout--blue">
                    <h2 class="widget-left-menu__header"></h2>
                    </div>
                </div>
                <div class="widget-left__body widget-left__body--compact widget-left__body--blue">
                    <div class="weather-left-card weather-left-card--compact">
                    <div class="weather-left-card__row1"><img src="" width="128" height="128" alt="" class="weather-left-card__img"/>
                    <div class="weather-left-card__col">
                        <p class="weather-left-card__number"><span class="weather-left-card__degree"></span></p><span class="weather-left-card__rising">and rising</span>
                    </div>
                </div>
                <div class="weather-left-card__row2">
                    <p class="weather-left-card__means"></p>
                    <p class="weather-left-card__wind">Wind:</p>
                </div>
                </div>
                <div class="widget-left-menu__links widget-left-menu__links--compact widget-left-menu__links--blue">
                    <div class="widget-left-menu__logo_container">
                        <a href="//openweathermap.org/" target="_blank"><div class="widget-left-menu__logo_black_small"></div></a>
                    </div>
                </div>
                </div>
              </div>`,
      },
      id3: {
        id: 3,
        type: 'left',
        schema: 'blue',
        domEmpty: `<div class="widget-left widget-left--small widget-left--blue">
                    <i class="widget-left__loading" />
                  </div>`,
        dom: `<div class="widget-left widget-left--small widget-left--blue">
                <div class="widget-left-menu widget-left-menu--small widget-left-menu--blue">
                  <div class="widget-left-menu__header-container">
                    <h2 class="widget-left-menu__header"></h2>
                  </div>
                  <p class="calendar calendar--small calendar--blue">
                    <img width="32" height="32" alt="" class="weather-left-card__img weather-left-card__img--small" src="">
                  </p>
                  <p class="weather-left-card__number weather-left-card__number--small"><span class="weather-left-card__degree"></span></p>
                </div>
                <div class="widget-left-menu__footer">
                  <div class="widget-left-menu__logo_container_float_left widget-left-menu__negative_top_container">
                    <a href="//openweathermap.org/" target="_blank"><div class="widget-left-menu__logo_black_small"></div></a>
                  </div>
                </div>
              </div>`,
      },
      id4: {
        id: 4,
        type: 'left',
        schema: 'blue',
        domEmpty: `<div class="widget-left widget-left--small widget-left--blue">
                    <i class="widget-left__loading" />
                  </div>`,
        dom: `<div class="widget-left widget-left--small2 widget-left--blue">
                <div class="widget-left-menu widget-left-menu--small widget-left-menu--blue">
                  <div class="widget-left-menu__header-container">
                    <h2 class="widget-left-menu__header"></h2>
                  </div>
                  <p class="calendar calendar--small2 calendar--blue">
                    <img width="64" height="64" alt="" class="weather-left-card__img weather-left-card__img--small" src="">
                  </p>
                  <p class="weather-left-card__number weather-left-card__number--small"><span class="weather-left-card__degree"></span></p>
                </div>
                <div class="widget-left-menu__footer">
                  <div class="widget-left-menu__logo_container_float_left widget-left-menu__negative_top_container">
                    <a href="//openweathermap.org/" target="_blank"><div class="widget-left-menu__logo_black_small"></div></a>
                  </div>
                </div>
              </div>`,
      },
      id5: {
        id: 5,
        type: 'right',
        schema: 'blue',
        domEmpty: `<div class="widget-right weather-right--type1 widget-right--blue">
                    <i class="widget-right__loading" />
                  </div>`,
        dom: `<div class="widget-right weather-right--type1 widget-right--blue">
                <div class="widget-right__header widget-right__header--blue">
                  <div class="widget-right__layout">
                    <div>
                      <h2 class="widget-right__title"></h2>
                      <p class="widget-right__description"></p>
                    </div>
                  </div><img width="128" height="128" alt="" class="weather-right__icon weather-right__icon--type1" src="">
                </div>
                <div class="weather-right weather-right--type1 weather-right--blue">
                  <div class="weather-right__layout">
                  <div class="weather-right__temperature"><span></span></div>
                  <div class="weather-right__weather">
                    <div class="weather-right-card">
                    <table class="weather-right__table">
                      <tbody><tr class="weather-right__items">
                      <th colspan="2" class="weather-right__item">Details</th>
                      </tr>
                      <tr class="weather-right__items">
                      <td class="weather-right__item">Feels like</td>
                      <td class="weather-right__item weather-right__feels"><span></span></td>
                      </tr>
                      <tr class="weather-right__items">
                      <td class="weather-right__item">Wind</td>
                      <td class="weather-right__item weather-right__wind-speed"></td>
                      </tr>
                      <tr class="weather-right-card__items">
                      <td class="weather-right__item">Humidity</td>
                      <td class="weather-right__item weather-right__humidity"></td>
                      </tr>
                      <tr class="weather-right-card__items">
                      <td class="weather-right__item">Precip</td>
                      <td class="weather-right__item"></td>
                      </tr>
                      <tr class="weather-right-card__items">
                      <td class="weather-right__item">Pressure</td>
                      <td class="weather-right__item weather-right__pressure"></td>
                      </tr>
                    </tbody></table>
                    </div>
                  </div>
                  </div>
                </div>
                <div class="widget-right__footer widget-right__footer--blue">
                  <div class="widget-right__layout"><a href="//openweathermap.org/" target="_blank"><div class="widget-right__logo_black_small"></div></a>
                  <div class="widget-right__date"></div>
                  </div>
                </div>
              </div>`
      },
      id6: {
        id: 6,
        type: 'right',
        schema: 'blue',
        domEmpty: `<div class="widget-right widget-right--type2 widget-right--blue">
                    <i class="widget-right__loading" />
                  </div>`,
        dom: `<div class="widget-right widget-right--type2 widget-right--blue">
                          <div class="widget-right__header widget-right__header--blue">
                            <div class="widget-right__layout">
                              <div>
                                <h2 class="widget-right__title"></h2>
                                <p class="widget-right__description"></p>
                              </div>
                            </div>
                          </div>
                          <div class="weather-right weather-right--blue weather-right--type2">
                            <div class="weather-right__layout">
                              <div class="weather-right__temperature"><span class="widget-right__units"></span></div>
                            </div>
                          </div>
                          <div class="widget-right__footer widget-right__footer--blue">
                            <div class="widget-right__layout">
                                <div class="widget-right__logo_container_full_width">
                                    <a href="//openweathermap.org/" target="_blank"><div class="widget-right__logo_black_extra_small"></div></a>
                                </div>
                            </div>
                          </div>
                      </div>`,
      },
      id7: {
        id: 7,
        type: 'right',
        schema: 'blue',
        domEmpty: `<div class="widget-right widget-right--type3 widget-right--blue">
                    <i class="widget-right__loading" />
                  </div>`,
        dom: `<div class="widget-right widget-right--type3 widget-right--blue">
                      <div class="widget-right__header widget-right__header--blue">
                        <div class="widget-right__layout">
                          <div>
                            <h2 class="widget-right__title"></h2>
                            <p class="widget-right__description"></p>
                          </div>
                        </div>
                      </div>
                      <div class="weather-right weather-right--blue weather-right--type3">
                        <div class="weather-right__layout">
                          <div class="weather-right__temperature"><span class="widget-right__units"></span></div>
                          <table class="weather-right-table weather-right-table--blue">
                            <tr class="weather-right-table__items">
                              <td class="weather-right-table__item">Feels Like</td>
                              <td class="weather-right-table__item weather-right__feels"></td>
                            </tr>
                            <tr class="weather-right-table__items">
                              <td class="weather-right-table__item">Wind</td>
                              <td class="weather-right-table__item weather-right__wind-speed"></td>
                            </tr>
                            <tr class="weather-right-table__items">
                              <td class="weather-right-table__item">Pressure</td>
                              <td class="weather-right-table__item weather-right__pressure"></td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <div class="widget-right__footer widget-right__footer--blue">
                        <div class="widget-right__layout">
                            <div class="widget-right__logo_container_full_width">
                                <a href="//openweathermap.org/" target="_blank"><div class="widget-right__logo_black_extra_small"></div></a>
                            </div>
                        </div>
                      </div>
                    </div>`
      },
      id8: {
        id: 8,
        type: 'right',
        schema: 'blue',
        domEmpty: `<div class="widget-right widget-right--type5 widget-right--blue">
                    <i class="widget-right__loading" />
                  </div>`,
        dom: `<div class="widget-right widget-right--type5 widget-right--blue">
                  <div class="widget-right__layout widget-right__layout--blue">
                    <div class="widget-right-card">
                      <div class="widget-right__title"></div>
                      <div class="widget-right__description"></div>
                    </div><img src="" width="64" height="64" alt="" class="weather-right__icon weather-right__icon--type5 weather-right__icon--blue"/>
                    <table class="weather-right-card">
                      <tr class="weather-right-card__items">
                        <td class="weather-right-card__item weather-right-card__temperature-min"></td>
                        <td class="weather-right-card__item weather-right-card__temperature-max"></td>
                      </tr>
                    </table>
                  </div>
                  <div class="widget-right__footer widget-right__footer--blue">
                      <div class="widget-right__layout">
                        <div class="widget-right__logo_container widget-right__negative_top_container">
                            <a href="//openweathermap.org/" target="_blank"><div class="widget-right__logo_black_small"></div></a>
                        </div>
                        <div class="widget-right__date"></div>
                      </div>
          
                    </div>
                  </div>
                </div>`,
      },
      id9: {
        id: 9,
        type: 'right',
        schema: 'blue',
        domEmpty: `<div class="widget-right widget-right--type4 widget-right--blue">
                    <i class="widget-right__loading" />
                  </div>`,
        dom: `<div class="widget-right widget-right--type4 widget-right--blue">
                  <div class="widget-right__layout widget-right__layout--blue">
                    <div class="widget-right-card">
                      <div class="widget-right__title"></div>
                      <div class="widget-right__description"></div>
                    </div><img src="" width="100" alt="" class="weather-right__icon weather-right__icon--type4 weather-right__icon--blue"/>
                    <table class="weather-right-card weather-right-card--type4 weather-right-card--blue">
                      <tr class="weather-right-card__items">
                        <td class="weather-right-card__item weather-right-card__temperature-min"></td>
                        <td class="weather-right-card__item weather-right-card__temperature-max"></td>
                      </tr>
                    </table>
                  </div>
                  <div class="widget-right__footer widget-right__footer--blue">
                      <div class="widget-right__layout">
                        <div class="widget-right__logo_container widget-right__negative_top_container">
                            <a href="//openweathermap.org/" target="_blank"><div class="widget-right__logo_black_small"></div></a>
                        </div>
                        <div class="widget-right__date"></div>
                      </div>
                    </div>
                  </div>
                </div>`,
      },
      id11: {
        id: 11,
        type: 'left',
        schema: 'brown',
        colorPolilyne: '#FEB020',
        domEmpty: `<div class="widget-left">
                        <i class="widget-left__loading" />
                    </div>`,
        dom: `<div class="widget-left">
                    <div class="widget-left-menu widget-left-menu--brown">
                      <div class="widget-left-menu__layout">
                        <h2 class="widget-left-menu__header"></h2>
                        <a href="//openweathermap.org/" target="_blank"><div class="widget-left-menu__logo_black"></div></a>
                      </div>
                    </div>
                    <div class="widget-left__body">
                      <div class="weather-left-card">
                        <div class="weather-left-card__row1"><img src="" width="128" height="128" alt="Weather" class="weather-left-card__img"/>
                          <div class="weather-left-card__col">
                            <p class="weather-left-card__number"><span class="weather-left-card__degree"></span></p><span class="weather-left-card__rising">and rising</span>
                          </div>
                        </div>
                        <div class="weather-left-card__row2">
                          <p class="weather-left-card__means"></p>
                          <p class="weather-left-card__wind">Wind:</p>
                        </div>
                      </div>
                      <div class="widget-left__calendar">
                        <ul class="calendar">
                          <li class="calendar__item"></li>
                          <li class="calendar__item"></li>
                          <li class="calendar__item"></li>
                          <li class="calendar__item"></li>
                          <li class="calendar__item"></li>
                          <li class="calendar__item"></li>
                          <li class="calendar__item"></li>
                          <li class="calendar__item"></li>
                        </ul>
                        <div id="graphic2" class="widget-left__graphic"></div>
                      </div>
                    </div>
                  </div>`,
      },
      id12: {
        id: 12,
        type: 'left',
        schema: 'brown',
        domEmpty: `<div class="widget-left widget-left--compact">
                     <i class="widget-left__loading" />
                  </div>`,
        dom: `<div class="widget-left widget-left--compact">
                      <div class="widget-left-menu widget-left-menu--compact widget-left-menu--brown">
                        <div class="widget-left-menu__layout widget-left-menu__layout--brown">
                          <h2 class="widget-left-menu__header">Weather</h2>
                        </div>
                      </div>
                      <div class="widget-left__body widget-left__body--compact">
                        <div class="weather-left-card weather-left-card--compact">
                          <div class="weather-left-card__row1"><img src="" width="128" height="128" alt="Weather" class="weather-left-card__img"/>
                            <div class="weather-left-card__col">
                              <p class="weather-left-card__number"><span class="weather-left-card__degree"></span></p><span class="weather-left-card__rising">and rising</span>
                            </div>
                          </div>
                          <div class="weather-left-card__row2">
                            <p class="weather-left-card__means"></p>
                            <p class="weather-left-card__wind">Wind:</p>
                          </div>
                        </div>
                        <div class="widget-left-menu__links widget-left-menu__links--compact widget-left-menu__links--brown">
                          <div class="widget-left-menu__logo_container">
                              <a href="//openweathermap.org/" target="_blank"><div class="widget-left-menu__logo_black_small"></div></a>
                          </div>
                        </div>
                      </div>
                    </div>`,
      },
      id13: {
        id: 13,
        type: 'left',
        schema: 'brown',
        domEmpty: `<div class="widget-left widget-left--small widget-left--brown">
                     <i class="widget-left__loading" />
                  </div>`,
        dom: `<div class="widget-left widget-left--small widget-left--brown">
                <div class="widget-left-menu widget-left-menu--small widget-left-menu--brown">
                <div class="widget-left-menu__header-container">
                  <h2 class="widget-left-menu__header"></h2>
                </div>
                <p class="calendar calendar--small calendar--brown">
                  <img width="32" height="32" alt="" class="weather-left-card__img weather-left-card__img--small" src="">
                </p>
                <p class="weather-left-card__number weather-left-card__number--small"><span class="weather-left-card__degree"></span></p>
                </div>
                <div class="widget-left-menu__footer">
                  <div class="widget-left-menu__logo_container_float_left widget-left-menu__negative_top_container">
                    <a href="//openweathermap.org/" target="_blank"><div class="widget-left-menu__logo_black_small"></div></a>
                  </div>
                </div>
              </div>`,
      },
      id14: {
        id: 14,
        type: 'left',
        schema: 'brown',
        domEmpty: `<div class="widget-left widget-left--small2 widget-left--brown">
                     <i class="widget-left__loading" />
                  </div>`,
        dom: `<div class="widget-left widget-left--small2 widget-left--brown">
                <div class="widget-left-menu widget-left-menu--small widget-left-menu--brown">
                <div class="widget-left-menu__header-container">
                  <h2 class="widget-left-menu__header"></h2>
                </div>
                <p class="calendar calendar--small2 calendar--brown">
                  <img width="64" height="64" alt="" class="weather-left-card__img weather-left-card__img--small" src="">
                </p>
                <p class="weather-left-card__number weather-left-card__number--small"><span class="weather-left-card__degree"></span></p>
                </div>
                <div class="widget-left-menu__footer">
                  <div class="widget-left-menu__logo_container_float_left widget-left-menu__negative_top_container">
                    <a href="//openweathermap.org/" target="_blank"><div class="widget-left-menu__logo_black_small"></div></a>
                  </div>
                </div>
              </div>`,
      },
      id15: {
        id: 15,
        type: 'right',
        schema: 'brown',
        domEmpty: `<div class="widget-right weather-right--type1 widget-right--brown">
                     <i class="widget-right__loading" />
                  </div>`,
        dom: `<div class="widget-right weather-right--type1 widget-right--brown">
                    <div class="widget-right__header widget-right__header--brown">
                      <div class="widget-right__layout">
                        <div>
                          <h2 class="widget-right__title"></h2>
                          <p class="widget-right__description"></p>
                        </div>
                      </div><img src="" width="128" height="128" alt="" class="weather-right__icon weather-right__icon--type1"/>
                    </div>
                    <div class="weather-right weather-right--brown">
                      <div class="weather-right__layout">
                        <div class="weather-right__temperature"><span class="widget-right__units"></span></div>
                        <div class="weather-right__weather">
                          <div class="weather-right-card">
                            <table class="weather-right__table">
                              <tr class="weather-right__items">
                                <th colspan="2" class="weather-right__item">Details</th>
                              </tr>
                              <tr class="weather-right__items">
                                <td class="weather-right__item">Feels like</td>
                                <td class="weather-right__item weather-right__feels"><span class="widget-right-card__units"></span></td>
                              </tr>
                              <tr class="weather-right__items">
                                <td class="weather-right__item">Wind</td>
                                <td class="weather-right__item weather-right__wind-speed"></td>
                              </tr>
                              <tr class="weather-right-card__items">
                                <td class="weather-right__item">Humidity</td>
                                <td class="weather-right__item weather-right__humidity"></td>
                              </tr>
                              <tr class="weather-right-card__items">
                                <td class="weather-right__item">Precip</td>
                                <td class="weather-right__item"></td>
                              </tr>
                              <tr class="weather-right-card__items">
                                <td class="weather-right__item">Pressure</td>
                                <td class="weather-right__item weather-right__pressure"></td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="widget-right__footer widget-right__footer--brown">
                      <div class="widget-right__layout"><a href="//openweathermap.org/" target="_blank"><div class="widget-right__logo_black_small"></div></a>
                        <div class="widget-right__date"></div>
                      </div>
                    </div>
                  </div>`,
      },
      id16: {
        id: 16,
        type: 'right',
        schema: 'brown',
        domEmpty: `<div class="wwidget-right widget-right--type2 widget-right--brown">
                     <i class="widget-right__loading" />
                  </div>`,
        dom: `<div class="widget-right widget-right--type2 widget-right--brown">
                      <div class="widget-right__header widget-right__header--brown">
                        <div class="widget-right__layout">
                          <div>
                            <h2 class="widget-right__title"></h2>
                            <p class="widget-right__description"></p>
                          </div>
                        </div>
                      </div>
                      <div class="weather-right weather-right--brown weather-right--type2">
                        <div class="weather-right__layout">
                          <div class="weather-right__temperature"><span class="widget-right__units"></span></div>
                        </div>
                      </div>
                      <div class="widget-right__footer widget-right__footer--brown">
                      <div class="widget-right__layout">
                            <div class="widget-right__logo_container_full_width">
                                <a href="//openweathermap.org/" target="_blank"><div class="widget-right__logo_black_extra_small"></div>
                            </div>
                        </div>
                      </div>
                    </div>`,
      },
      id17: {
        id: 17,
        type: 'right',
        schema: 'brown',
        domEmpty: `<div class="widget-right widget-right--type3 widget-right--brown">
                     <i class="widget-right__loading" />
                  </div>`,
        dom: `<div class="widget-right widget-right--type3 widget-right--brown">
                      <div class="widget-right__header widget-right__header--brown">
                        <div class="widget-right__layout">
                          <div>
                            <h2 class="widget-right__title"></h2>
                            <p class="widget-right__description"></p>
                          </div>
                        </div>
                      </div>
                      <div class="weather-right weather-right--brown weather-right--type3">
                        <div class="weather-right__layout">
                          <div class="weather-right__temperature"><span class="widget-right__units"></span></div>
                          <table class="weather-right-table">
                            <tr class="weather-right-table__items">
                              <td class="weather-right-table__item">Feels Like</td>
                              <td class="weather-right-table__item weather-right__feels"></td>
                            </tr>
                            <tr class="weather-right-table__items">
                              <td class="weather-right-table__item">Wind</td>
                              <td class="weather-right-table__item weather-right__wind-speed"></td>
                            </tr>
                            <tr class="weather-right-table__items">
                              <td class="weather-right-table__item">Pressure</td>
                              <td class="weather-right-table__item weather-right__pressure"></td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <div class="widget-right__footer widget-right__footer--brown">
                        <div class="widget-right__layout">
                            <div class="widget-right__logo_container_full_width">
                                <a href="//openweathermap.org/" target="_blank"><div class="widget-right__logo_black_extra_small"></div></a>
                            </div>
                        </div>
                      </div>
                    </div>`,
      },
      id18: {
        id: 18,
        type: 'right',
        schema: 'brown',
        domEmpty: `<div class="widget-right widget-right--type5 widget-right--brown">
                     <i class="widget-right__loading" />
                  </div>`,
        dom: `<div class="widget-right widget-right--type5 widget-right--brown">
                  <div class="widget-right__layout widget-right__layout--brown">
                    <div class="widget-right-card">
                      <div class="widget-right__title"></div>
                      <div class="widget-right__description"></div>
                    </div><img src="" width="64" height="64" alt="" class="weather-right__icon weather-right__icon--type5 weather-right__icon--brown"/>
                    <table class="weather-right-card">
                      <tr class="weather-right-card__items">
                        <td class="weather-right-card__item weather-right-card__temperature-min"></td>
                        <td class="weather-right-card__item weather-right-card__temperature-max"></td>
                      </tr>
                    </table>
                  </div>
                  <div class="widget-right__footer widget-right__footer--brown">
                    <div class="widget-right__layout">
                        <div class="widget-right__logo_container widget-right__negative_top_container">
                            <a href="//openweathermap.org/" target="_blank"><div class="widget-right__logo_black_small"></div></a>
                        </div>
                        <div class="widget-right__date"></div>
                      </div>
                  </div>
                </div>`,
      },
      id19: {
        id: 19,
        type: 'right',
        schema: 'brown',
        domEmpty: `<div class="widget-right widget-right--type4 widget-right--brown">
                     <i class="widget-right__loading" />
                  </div>`,
        dom: `<div class="widget-right widget-right--type4 widget-right--brown">
                  <div class="widget-right__layout widget-right__layout--brown">
                    <div class="widget-right-card">
                      <div class="widget-right__title"></div>
                      <div class="widget-right__description"></div>
                    </div><img src="" width="100" alt="" class="weather-right__icon weather-right__icon--type4 weather-right__icon--brown"/>
                    <table class="weather-right-card">
                      <tr class="weather-right-card__items">
                        <td class="weather-right-card__item weather-right-card__temperature-min"></td>
                        <td class="weather-right-card__item weather-right-card__temperature-max"></td>
                      </tr>
                    </table>
                  </div>
                  <div class="widget-right__footer widget-right__footer--brown">
                    <div class="widget-right__layout">
                      <div class="widget-right__logo_container widget-right__negative_top_container">
                          <a href="//openweathermap.org/" target="_blank"><div class="widget-right__logo_black_small"></div></a>
                      </div>
                      <div class="widget-right__date"></div>
                    </div>
                  </div>
                </div>`,
      },
      id21: {
        id: 21,
        type: 'left',
        schema: 'white',
        domEmpty: `<div class="widget-left">
                     <i class="widget-left__loading" />
                  </div>`,
        dom: `<div class="widget-left">
                  <div class="widget-left-menu">
                    <div class="widget-left-menu__layout">
                      <h2 class="widget-left-menu__header"></h2>
                      <a href="//openweathermap.org/" target="_blank"><div class="widget-left-menu__logo_bnw"></div></a>
                    </div>
                  </div>
                  <div class="widget-left__body">
                    <div class="weather-left-card weather-left-card--grayscale">
                      <div class="weather-left-card__row1"><img src="" width="128" height="128" alt="Weather" class="weather-left-card__img"/>
                        <div class="weather-left-card__col">
                          <p class="weather-left-card__number"><span class="weather-left-card__degree"></span></p><span class="weather-left-card__rising">and rising</span>
                        </div>
                      </div>
                      <div class="weather-left-card__row2">
                        <p class="weather-left-card__means"></p>
                        <p class="weather-left-card__wind">Wind:</p>
                      </div>
                    </div>
                    <div class="widget-left__calendar">
                      <ul class="calendar calendar--grayscale">
                        <li class="calendar__item"></li>
                        <li class="calendar__item"></li>
                        <li class="calendar__item"></li>
                        <li class="calendar__item"></li>
                        <li class="calendar__item"></li>
                        <li class="calendar__item"></li>
                        <li class="calendar__item"></li>
                        <li class="calendar__item"></li>
                      </ul>
                      <div id="graphic" class="widget-left__graphic"></div>
                    </div>
                  </div>
                </div>`,
      },
      id22: {
        id: 22,
        type: 'left',
        schema: 'white',
        domEmpty: `<div class="widget-left widget-left--compact">
                     <i class="widget-left__loading" />
                  </div>`,
        dom: `<div class="widget-left widget-left--compact">
                  <div class="widget-left-menu widget-left-menu--compact">
                    <div class="widget-left-menu__layout">
                      <h2 class="widget-left-menu__header"></h2>
                    </div>
                  </div>
                  <div class="widget-left__body widget-left__body--compact">
                    <div class="weather-left-card weather-left-card--compact weather-left-card--grayscale">
                      <div class="weather-left-card__row1"><img src="" width="128" height="128" alt="Weather" class="weather-left-card__img"/>
                        <div class="weather-left-card__col">
                          <p class="weather-left-card__number"><span class="weather-left-card__degree"></span></p><span class="weather-left-card__rising">and rising</span>
                        </div>
                      </div>
                      <div class="weather-left-card__row2">
                        <p class="weather-left-card__means"></p>
                        <p class="weather-left-card__wind">Wind:</p>
                      </div>
                    </div>
                    <div class="widget-left-menu__links widget-left-menu__links--compact widget-left-menu__links--grey">
                      <div class="widget-left-menu__logo_container">
                          <a href="//openweathermap.org/" target="_blank"><div class="widget-left-menu__logo_bnw_small"></div></a>
                      </div>
                    </div>
                  </div>
                </div>`,
      },
      id23: {
        id: 23,
        type: 'left',
        schema: 'white',
        domEmpty: `<div class="widget-left widget-left--small">
                     <i class="widget-left__loading" />
                  </div>`,
        dom: `<div class="widget-left widget-left--small">
                <div class="widget-left-menu widget-left-menu--small">
                  <div class="widget-left-menu__header-container">
                    <h2 class="widget-left-menu__header"></h2>
                  </div>
                  <p class="calendar calendar--grayscale calendar--small">
                    <img width="32" height="32" alt="" class="weather-left-card__img weather-left-card__img--small" src="">
                  </p>
                  <p class="weather-left-card__number weather-left-card__number--small"><span class="weather-left-card__degree"></span></p>
                </div>
                <div class="widget-left-menu__footer">
                  <div class="widget-left-menu__logo_container_float_left widget-left-menu__negative_top_container">
                    <a href="//openweathermap.org/" target="_blank"><div class="widget-left-menu__logo_bnw_small"></div></a>
                  </div>
                </div>
              </div>`,
      },
      id24: {
        id: 24,
        type: 'left',
        schema: 'white',
        domEmpty: `<div class="widget-left widget-left--small2">
                     <i class="widget-left__loading" />
                  </div>`,
        dom: `<div class="widget-left widget-left--small2">
                <div class="widget-left-menu widget-left-menu--small">
                  <div class="widget-left-menu__header-container">
                    <h2 class="widget-left-menu__header"></h2>
                  </div>
                  <p class="calendar calendar--grayscale calendar--small2">
                    <img width="64" height="64" alt="" class="weather-left-card__img weather-left-card__img--small" src="">
                  </p>
                  <p class="weather-left-card__number weather-left-card__number--small"><span class="weather-left-card__degree"></span></p>
                </div>
                <div class="widget-left-menu__footer">
                  <div class="widget-left-menu__logo_container_float_left widget-left-menu__negative_top_container">
                    <a href="//openweathermap.org/" target="_blank"><div class="widget-left-menu__logo_bnw_small"></div></a>
                  </div>
                </div>
              </div>`,
      },
      id31: {
        id: 31,
        type: 'left',
        schema: 'brown',
        domEmpty: `<div class="widget-left">
                     <i class="widget-left__loading" />
                  </div>`,
        dom: `<div class="widget-left">
                <div class="widget-left-menu widget-left-menu--brown">
                  <div class="widget-left-menu__layout">
                    <h2 class="widget-left-menu__header">Weather</h2><img src="//openweathermap.org/themes/openweathermap/assets/vendor/owm/img/widgets/vetochki01.png" alt="Merry Christmas" class="widget-left-menu__christmas"/>
                  </div>
                </div>
                <div class="widget-left__body">
                  <div class="weather-left-card">
                    <div class="weather-left-card__row1"><img width="128" height="128" alt="Weather" class="weather-left-card__img"/>
                      <div class="weather-left-card__col">
                        <p class="weather-left-card__number"><span class="weather-left-card__degree"></span></p><span class="weather-left-card__rising">and rising</span>
                      </div>
                    </div>
                    <div class="weather-left-card__row2">
                      <p class="weather-left-card__means"></p>
                      <p class="weather-left-card__wind">Wind:</p>
                    </div>
                    <div class="widget-left-menu__links widget-left-menu__links--christmas"><span>More at</span><a href="//openweathermap.org/" class="widget-left-menu__link">OpenWeatherMap</a></div>
                  </div>
                  <div class="widget-left__calendar">
                    <ul class="calendar">
                      <li class="calendar__item">Today</li>
                      <li class="calendar__item"></li>
                      <li class="calendar__item"></li>
                      <li class="calendar__item"></li>
                      <li class="calendar__item"></li>
                      <li class="calendar__item"></li>
                      <li class="calendar__item"></li>
                      <li class="calendar__item"></li>
                    </ul>
                    <div id="graphic3" class="widget-left__graphic"></div>
                  </div>
                </div>`
      },
    }
    this.lincsCSS = {
      all: `<link href="${this.baseURL}/css/style.min.css" rel="stylesheet" />`,
      left: `<link href="${this.baseURL}/css/openweathermap-widget-left.min.css" rel="stylesheet" />`,
      right: `<link href="${this.baseURL}/css/openweathermap-widget-right.min.css" rel="stylesheet" />`,
    };
  }

  getCodeForGenerateWidget(id, cityid, city_name, key) {
    if (id && (cityid || city_name) && key) {
      return ` <script type="text/javascript">
                                window.myWidgetParam = {
                                    id: ${id},
                                    cityid: ${city_id},
                                    containerid: 'openweathermap-widget',
                                };
                                (function() {
                                    var script = document.createElement('script');
                                    script.type = 'text/javascript';
                                    script.async = true;
                                    script.src = ${this.scriptSRC};
                                    var s = document.getElementsByTagName('script')[0];
                                    s.parentNode.insertBefore(script, s);
                                })();
            </script>`;
    }
    return null;
  }
  render(myWidgetParam) {
    if (myWidgetParam) {
      this.selDOMElement = this.domElements[`id${myWidgetParam.id}`];
      var container = document.getElementById(myWidgetParam.containerid);
      container.insertAdjacentHTML('afterbegin', this.selDOMElement.dom);
      container.insertAdjacentHTML('afterbegin', this.lincsCSS[this.selDOMElement.type]);

      // вставка счетчика гугла
      let googleCode = `<script>
         (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
         (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
         m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
         })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

         ga('create', 'UA1601618-11', 'auto');
         ga('send', 'pageview');

        </script>`

      container.insertAdjacentHTML('beforeend', googleCode);
    }
  }

  getDocumentFragment(myWidgetParam) {
    const documentFragment = document.createDocumentFragment();
    // контейнер для отрисовки виджетов
    const container = document.createElement('div');
    // контейнер для отображения процесса загрузки виджетов
    const containerLoader = document.createElement('div');
    if (myWidgetParam) {
      container.id = `container-${myWidgetParam.containerid}`;
      this.selDOMElement = this.domElements[`id${myWidgetParam.id}`];
      container.insertAdjacentHTML('afterbegin', this.selDOMElement.dom);
      container.insertAdjacentHTML('afterbegin', this.lincsCSS[this.selDOMElement.type]);

      // вставка счетчика гугла
      let googleCode = `<script>
         (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
         (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
         m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
         })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

         ga('create', 'UA1601618-11', 'auto');
         ga('send', 'pageview');

        </script>`;
      container.insertAdjacentHTML('beforeend', googleCode);
      documentFragment.appendChild(container);
      containerLoader.insertAdjacentHTML('afterbegin', this.selDOMElement.domEmpty);
      containerLoader.insertAdjacentHTML('afterbegin', this.lincsCSS[this.selDOMElement.type]);
    }
    return {
      documentFragment: documentFragment,
      containerLoader: containerLoader
    };
  }
}
