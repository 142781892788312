/**
 * Created by Denis on 29.09.2016.
 */
const Promise = require('es6-promise').Promise;
import CustomDate from './custom-date';
import Graphic from './graphic-d3js';
import { naturalPhenomenon } from './data/natural-phenomenon-data';
import { windSpeed } from './data/wind-speed-data';
import { windDirection } from './data/wind-speed-data';
import $ from 'jquery';
export default class WeatherWidget extends CustomDate {

  constructor(params, controls, urls) {
    super();
    this.params = params;
    this.controls = controls;
    this.urls = urls;
    this.id = params.id;

    this.existGraphic = this.controls.graphic ||
      this.controls.graphic1 ||
      this.controls.graphic2 ||
      this.controls.graphic3;

    // Инициализируем объект пустыми значениями
    this.weather = {
      fromAPI: {
        coord: {
          lon: '0',
          lat: '0',
        },
        weather: [{
          id: ' ',
          main: ' ',
          description: ' ',
          icon: ' ',
        }],
        base: ' ',
        main: {
          temp: 0,
          pressure: ' ',
          humidity: ' ',
          temp_min: ' ',
          temp_max: ' ',
        },
        wind: {
          speed: 0,
          deg: ' ',
        },
        rain: {},
        clouds: {
          all: ' ',
        },
        dt: '',
        sys: {
          type: ' ',
          id: ' ',
          message: ' ',
          country: ' ',
          sunrise: ' ',
          sunset: ' ',
        },
        id: ' ',
        name: 'Undefined',
        cod: ' ',
      },
    };

    this.naturalPhenomenon = naturalPhenomenon;
    this.windSpeed = windSpeed;
    this.windDirection = windDirection;

  }

  /**
   * Запрос к API для получения данных текущей погоды
   */
  getWeatherFromApi() {
    let forecastCurrent;
    let forecastDaily;
    this.getForecastCurrent()
      .done(weather => {
        forecastCurrent = weather;
        if (this.existGraphic) {
          this.getForecastDaily()
            .catch(() => this.prepareToParseMetadata(this.weather, forecastCurrent))
            .done(weatherDaily => {
              forecastDaily = weatherDaily;
              this.prepareToParseMetadata(this.weather, forecastCurrent, forecastDaily);
            });
        } else {
          this.prepareToParseMetadata(this.weather, forecastCurrent);
        }
      });
  }

  getForecastCurrent() {
    const urlWeatherAPI = this.urls.urlWeatherAPI;
    const paramsAjaxWeatherCurrent = {
      dataType: 'jsonp',
      url: urlWeatherAPI,
      context: this,
      xhrFields: {
        withCredentials: false
      }
    };
    return $.ajax(paramsAjaxWeatherCurrent);
  }

  getForecastDaily() {
    const paramsUrlForeDaily = this.urls.paramsUrlForeDaily;
    const paramsAjaxWeatherDaily = {
      dataType: 'jsonp',
      url: paramsUrlForeDaily,
      context: this,
      xhrFields: {
        withCredentials: false
      }
    };
    return $.ajax(paramsAjaxWeatherDaily);
  }

  prepareToParseMetadata(weather, forecastCurrent, forecastDaily) {
    weather.fromAPI = forecastCurrent;
    weather.naturalPhenomenon = this.naturalPhenomenon[this.params.lang].description;
    weather.windSpeed = this.windSpeed[this.params.lang];
    weather.forecastDaily = forecastDaily;
    this.parseDataFromServer(weather);
  }

  /**
   * Метод возвращает родительский селектор по значению дочернего узла в JSON
   * @param {object} JSON
   * @param {variant} element Значение элементарного типа, дочернего узла для поиска родительского
   * @param {string} elementName Наименование искомого селектора,для поиска родительского селектора
   * @return {string} Наименование искомого селектора
   */
  getParentSelectorFromObject(object, element, elementName, elementName2) {
    for (let key in object) {
      // Если сравнение производится с объектом из двух элементов ввиде интервала
      if (typeof object[key][elementName] === 'object' && elementName2 == null) {
        if (element >= object[key][elementName][0] && element < object[key][elementName][1]) {
          return key;
        }
        // сравнение производится со значением элементарного типа с двумя элементами в JSON
      } else if (elementName2 != null) {
        if (element >= object[key][elementName] && element < object[key][elementName2]) {
          return key;
        }
      }
    }
  }

  /**
   * Возвращает JSON с метеодаными
   * @param jsonData
   * @returns {*}
   */
  parseDataFromServer(weather) {

    if (weather.fromAPI.name === 'Undefined' || weather.fromAPI.cod === '404') {
      return;
    }

    // Инициализируем объект
    const metadata = {
      cloudiness: ' ',
      dt: ' ',
      cityName: ' ',
      icon: ' ',
      temperature: ' ',
      temperatureMin: ' ',
      temperatureMax: ' ',
      pressure: ' ',
      humidity: ' ',
      sunrise: ' ',
      sunset: ' ',
      coord: ' ',
      wind: ' ',
      weather: ' ',
    };
    const temperature = parseInt(weather.fromAPI.main.temp.toFixed(0), 10) + 0;
    metadata.cityName = `${weather.fromAPI.name}, ${weather.fromAPI.sys.country}`;
    metadata.temperature = temperature; // `${temp > 0 ? `+${temp}` : temp}`;
    metadata.temperatureMin = parseInt(weather.fromAPI.main.temp_min.toFixed(0), 10) + 0;
    metadata.temperatureMax = parseInt(weather.fromAPI.main.temp_max.toFixed(0), 10) + 0;
    if (weather.naturalPhenomenon) {
      metadata.weather = weather.naturalPhenomenon[weather.fromAPI.weather[0].id];
    }
    if (weather.windSpeed) {
      metadata.windSpeed = `Wind: ${weather.fromAPI.wind.speed.toFixed(1)} ${this.params.textUnitWind} ${this.getParentSelectorFromObject(weather.windSpeed, weather.fromAPI.wind.speed.toFixed(1), 'speed_interval')}`;
      metadata.windSpeed2 = `${weather.fromAPI.wind.speed.toFixed(1)} ${this.params.textUnitWind}`;
    }
    if (weather.windDirection) {
      metadata.windDirection = `${this.getParentSelectorFromObject(weather["windDirection"], weather["fromAPI"]["wind"]["deg"], "deg_interval")}`
    }
    if (weather.clouds) {
      metadata.clouds = `${this.getParentSelectorFromObject(weather.clouds, weather.fromAPI.clouds.all, 'min', 'max')}`;
    }

    metadata.humidity = `${weather.fromAPI.main.humidity}%`;
    metadata.pressure =  `${weather["fromAPI"]["main"]["pressure"]} hPa`;
    metadata.icon = `${weather.fromAPI.weather[0].icon}`;

    this.renderWidget(metadata);
  }

  renderWidget(metadata) {
    // Оотрисовка первых четырех виджетов
    for (let elem in this.controls.cityName) {
      if (this.controls.cityName.hasOwnProperty(elem)) {
        this.controls.cityName[elem].innerHTML = metadata.cityName;
      }
    }

    for (let elem in this.controls.temperature) {
      if (this.controls.temperature.hasOwnProperty(elem)) {
        this.controls.temperature[elem].innerHTML = `${metadata.temperature}<span class='weather-left-card__degree'>${this.params.textUnitTemp}</span>`;
      }
    }

    for (let elem in this.controls.mainIconWeather) {
      if (this.controls.mainIconWeather.hasOwnProperty(elem)) {
        this.controls.mainIconWeather[elem].src = this.getURLMainIcon(metadata.icon, true);
        this.controls.mainIconWeather[elem].alt = `Weather in ${metadata.cityName ? metadata.cityName : ''}`;
      }
    }

    if (metadata.weather) {
      for (let elem in this.controls.naturalPhenomenon) {
        if (this.controls.naturalPhenomenon.hasOwnProperty(elem)) {
          this.controls.naturalPhenomenon[elem].innerText = metadata.weather;
        }
      }
    }
    if (metadata.windSpeed) {
      for (let elem in this.controls.windSpeed) {
        if (this.controls.windSpeed.hasOwnProperty(elem)) {
          this.controls.windSpeed[elem].innerText = metadata.windSpeed;
        }
      }
    }

    // Отрисовка пяти последних виджетов
    for (let elem in this.controls.cityName2) {
      if (this.controls.cityName2.hasOwnProperty(elem)) {
        this.controls.cityName2[elem].innerHTML = metadata.cityName;
      }
    }

    for (let elem in this.controls.temperature2) {
      if (this.controls.temperature2.hasOwnProperty(elem)) {
        if (this.controls.temperature2[elem]) {
          this.controls.temperature2[elem].innerHTML = `${metadata.temperature}<span>${this.params.textUnitTemp}</span>`;
        }
      }
      if (this.controls.temperatureFeels.hasOwnProperty(elem)) {
        this.controls.temperatureFeels[elem].innerHTML = `${metadata.temperature}<span>${this.params.textUnitTemp}</span>`;
      }
    }

    for (let elem in this.controls.temperatureMin) {
      if (this.controls.temperatureMin.hasOwnProperty(elem)) {
        this.controls.temperatureMin[elem].innerHTML = `${metadata.temperatureMin}<span>${this.params.textUnitTemp}</span>`;
      }
    }

    for (let elem in this.controls.temperatureMax) {
      if (this.controls.temperatureMax.hasOwnProperty(elem)) {
        this.controls.temperatureMax[elem].innerHTML = `${metadata.temperatureMax}<span>${this.params.textUnitTemp}</span>`;
      }
    }

    if (metadata.weather) {
      for (let elem in this.controls.naturalPhenomenon2) {
        if (this.controls.naturalPhenomenon2.hasOwnProperty(elem)) {
          this.controls.naturalPhenomenon2[elem].innerText = metadata.weather;
        }
      }
    }

    if (metadata.windSpeed2) {
      for (const elem in this.controls.windSpeed2) {
        if (this.controls.windSpeed2.hasOwnProperty(elem)) {
          this.controls.windSpeed2[elem].innerText = `${metadata.windSpeed2} ${metadata.windDirection || ''}`;
        }
      }
    }

    for (let elem in this.controls.mainIconWeather2) {
      if (this.controls.mainIconWeather2.hasOwnProperty(elem)) {
        this.controls.mainIconWeather2[elem].src = this.getURLMainIcon(metadata.icon, true);
        this.controls.mainIconWeather2[elem].alt = `Weather in ${metadata.cityName ? metadata.cityName : ''}`;
      }
    }

    if (metadata.humidity) {
      for (let elem in this.controls.humidity) {
        if (this.controls.humidity.hasOwnProperty(elem)) {
          this.controls.humidity[elem].innerText = metadata.humidity;
        }
      }
    }

    if (metadata.pressure) {
      for (let elem in this.controls.pressure) {
        if (this.controls.pressure.hasOwnProperty(elem)) {
          this.controls.pressure[elem].innerText = metadata.pressure;
        }
      }
    }

    // Прописываем текущую дату в виджеты
    for (let elem in this.controls.dateReport) {
      if (this.controls.dateReport.hasOwnProperty(elem)) {
        this.controls.dateReport[elem].innerText = this.getTimeDateHHMMMonthDay();
      }
    }

    const documentFragmentWidget =
      this.controls.documentFragmentWidget.querySelector(`#container-${this.controls.containerWidget.id}`);
    if (documentFragmentWidget) {
      this.controls.containerWidget.innerHTML = '';
      this.controls.containerWidget.appendChild(documentFragmentWidget);
    }

    if(this.existGraphic) {
      if (Object.keys(this.weather.forecastDaily).length > 0) {
        this.prepareDataForGraphic();
      }
    }

  }

  prepareDataForGraphic() {
    const arr = [];

    this.weather.forecastDaily.list.forEach((elem) => {
      const day = this.getDayNameOfWeekByDayNumber(this.getNumberDayInWeekByUnixTime(elem.dt));
      const dtAPI = new Date(elem.dt * 1000);
      const dt = new Date();
      arr.push({
        min: Math.round(elem.temp.min),
        max: Math.round(elem.temp.max),
        day: (dtAPI.getFullYear() === dt.getFullYear() &&
              dtAPI.getMonth() === dt.getMonth() &&
              dtAPI.getDate() === dt.getDate()) ? 'Today' : day,
        icon: elem.weather[0].icon,
        date: this.timestampToDateTime(elem.dt),
        dt: elem.dt,
      });
    });
    return this.drawGraphicD3(arr.slice(0, 8));
  }

  /**
   * Отрисовка названия дней недели и иконок с погодой
   * @param data
   */
  renderIconsDaysOfWeek(data) {
    const calendarItems = this.controls.calendar.querySelectorAll('.calendar__item');
    data.forEach((elem, index) => {
      const date = new Date(elem.dt * 1000);
      calendarItems[index].innerHTML = `${elem.day}<br>${date.getDate()} ${this.getMonthNameByMonthNumber(date.getMonth())}<img src="${document.location.protocol}//openweathermap.org/img/w/${elem.icon}.png" width="32" height="32" alt="${elem.day}">`;
    });
    return data;
  }


  getURLMainIcon(nameIcon, color = false) {
    // Создаем и инициализируем карту сопоставлений
    const mapIcons = new Map();

    if (!color) {
      //
      mapIcons.set('01d', '01dbw');
      mapIcons.set('02d', '02dbw');
      mapIcons.set('03d', '03dbw');
      mapIcons.set('03d', '03dbw');
      mapIcons.set('04d', '04dbw');
      mapIcons.set('05d', '05dbw');
      mapIcons.set('06d', '06dbw');
      mapIcons.set('07d', '07dbw');
      mapIcons.set('08d', '08dbw');
      mapIcons.set('09d', '09dbw');
      mapIcons.set('10d', '10dbw');
      mapIcons.set('11d', '11dbw');
      mapIcons.set('13d', '13dbw');
      // Ночные
      mapIcons.set('01n', '01dbw');
      mapIcons.set('02n', '02dbw');
      mapIcons.set('03n', '03dbw');
      mapIcons.set('03n', '03dbw');
      mapIcons.set('04n', '04dbw');
      mapIcons.set('05n', '05dbw');
      mapIcons.set('06n', '06dbw');
      mapIcons.set('07n', '07dbw');
      mapIcons.set('08n', '08dbw');
      mapIcons.set('09n', '09dbw');
      mapIcons.set('10n', '10dbw');
      mapIcons.set('11n', '11dbw');
      mapIcons.set('13n', '13dbw');

      if (mapIcons.get(nameIcon)) {
        return `${this.params.baseURL}/img/widgets/${nameIcon}img/${mapIcons.get(nameIcon)}.png`;
      }
      return `http://openweathermap.org/img/w/${nameIcon}.png`; // Захардковежно до времен https
    }
    return `${this.params.baseURL}/img/widgets/${nameIcon}.png`;
  }

  /**
   * Отрисовка графика с помощью библиотеки D3
   */
  drawGraphicD3(data) {
    this.renderIconsDaysOfWeek(data);
    // Параметризуем область отрисовки графика
    const params = {
      id: '#graphic',
      data,
      offsetX: 15,
      offsetY: 10,
      width: 420,
      height: 79,
      rawData: [],
      margin: 10,
      colorPolilyne: '#333',
      fontSize: '12px',
      fontColor: '#333',
      strokeWidth: '1px',
    };
    // Очистка контейнеров для графиков
    if (this.id === 1) {
      const svg1 = document.getElementById('graphic1');
      if (svg1) {
        if (svg1.querySelector('svg')) {
          svg1.removeChild(svg1.querySelector('svg'));
        }
        params.id = '#graphic1';
        params.colorPolilyne = '#DDF730';
      }
    }
    if (this.id === 11) {
      const svg2 = document.getElementById('graphic2');
      if (svg2) {
        if (svg2.querySelector('svg')) {
          svg2.removeChild(svg2.querySelector('svg'));
        }
        params.id = '#graphic2';
        params.colorPolilyne = '#FEB020';
      }
    }
    if (this.id === 21) {
      const svg = document.getElementById('graphic');
      if (svg) {
        if (svg.querySelector('svg')) {
          svg.removeChild(svg.querySelector('svg'));
        }
      }
    }
    if (this.id === 31) {
      const svg3 = document.getElementById('graphic3');
      if (svg3) {
        if (svg3.querySelector('svg')) {
          svg3.removeChild(svg3.querySelector('svg'));
        }
        params.id = '#graphic3';
        params.colorPolilyne = '#FEB020';
      }
    }
    const objGraphicD3 = new Graphic(params);
    objGraphicD3.render();
  }

  render() {
    this.getWeatherFromApi();
  }

}
