/**
 * Created by Denis on 20.10.2016.
 */
export const naturalPhenomenon ={
    "en":{
    "name":"English",
        "main":"",
        "description":{
        "200":"thunderstorm with light rain",
            "201":"thunderstorm with rain",
            "202":"thunderstorm with heavy rain",
            "210":"light thunderstorm",
            "211":"thunderstorm",
            "212":"heavy thunderstorm",
            "221":"ragged thunderstorm",
            "230":"thunderstorm with light drizzle",
            "231":"thunderstorm with drizzle",
            "232":"thunderstorm with heavy drizzle",
            "300":"light intensity drizzle",
            "301":"drizzle",
            "302":"heavy intensity drizzle",
            "310":"light intensity drizzle rain",
            "311":"drizzle rain",
            "312":"heavy intensity drizzle rain",
            "313":"shower rain and drizzle",
            "314":"heavy shower rain and drizzle",
            "321":"shower drizzle",
            "500":"light rain",
            "501":"moderate rain",
            "502":"heavy intensity rain",
            "503":"very heavy rain",
            "504":"extreme rain",
            "511":"freezing rain",
            "520":"light intensity shower rain",
            "521":"shower rain",
            "522":"heavy intensity shower rain",
            "531":"ragged shower rain",
            "600":"light snow",
            "601":"snow",
            "602":"heavy snow",
            "611":"sleet",
            "612":"shower sleet",
            "615":"light rain and snow",
            "616":"rain and snow",
            "620":"light shower snow",
            "621":"shower snow",
            "622":"heavy shower snow",
            "701":"mist",
            "711":"smoke",
            "721":"haze",
            "731":"sand,dust whirls",
            "741":"fog",
            "751":"sand",
            "761":"dust",
            "762":"volcanic ash",
            "771":"squalls",
            "781":"tornado",
            "800":"clear sky",
            "801":"few clouds",
            "802":"scattered clouds",
            "803":"broken clouds",
            "804":"overcast clouds",
            "900":"tornado",
            "901":"tropical storm",
            "902":"hurricane",
            "903":"cold",
            "904":"hot",
            "905":"windy",
            "906":"hail",
            "950":"setting",
            "951":"calm",
            "952":"light breeze",
            "953":"gentle breeze",
            "954":"moderate breeze",
            "955":"fresh breeze",
            "956":"strong breeze",
            "957":"high wind, near gale",
            "958":"gale",
            "959":"severe gale",
            "960":"storm",
            "961":"violent storm",
            "962":"hurricane"
    }
},
    "ru":{
    "name":"Russian",
        "main":"",
        "description":{
        "200":"\u0433\u0440\u043e\u0437\u0430 \u0441 \u043c\u0435\u043b\u043a\u0438\u043c \u0434\u043e\u0436\u0434\u0451\u043c",
            "201":"\u0433\u0440\u043e\u0437\u0430 \u0441 \u0434\u043e\u0436\u0434\u0451\u043c",
            "202":"\u0433\u0440\u043e\u0437\u0430 \u0441 \u043f\u0440\u043e\u043b\u0438\u0432\u043d\u044b\u043c \u0434\u043e\u0436\u0434\u0451\u043c",
            "210":"\u0432\u043e\u0437\u043c\u043e\u0436\u043d\u0430 \u0433\u0440\u043e\u0437\u0430",
            "211":"\u0433\u0440\u043e\u0437\u0430",
            "212":"\u0431\u0443\u0440\u044f",
            "221":"\u0436\u0435\u0441\u0442\u043e\u043a\u0430\u044f \u0433\u0440\u043e\u0437\u0430",
            "230":"\u0433\u0440\u043e\u0437\u0430 \u0441 \u043c\u0435\u043b\u043a\u0438\u043c \u0434\u043e\u0436\u0434\u0451\u043c",
            "231":"\u0433\u0440\u043e\u0437\u0430 \u0441 \u0434\u043e\u0436\u0434\u0451\u043c",
            "232":"\u0433\u0440\u043e\u0437\u0430 \u0441 \u0441\u0438\u043b\u044c\u043d\u044b\u043c \u0434\u043e\u0436\u0434\u0451\u043c",
            "300":"\u0441\u044b\u0440\u043e",
            "301":"\u0441\u044b\u0440\u043e",
            "302":"\u043e\u0447\u0435\u043d\u044c \u0441\u044b\u0440\u043e",
            "310":"\u043b\u0451\u0433\u043a\u0438\u0439 \u0434\u043e\u0436\u0434\u044c",
            "311":"\u043b\u0451\u0433\u043a\u0438\u0439 \u0434\u043e\u0436\u0434\u044c",
            "312":"\u0438\u043d\u0442\u0435\u043d\u0441\u0438\u0432\u043d\u044b\u0439 \u0434\u043e\u0436\u0434\u044c",
            "321":"\u043c\u0435\u043b\u043a\u0438\u0439 \u0434\u043e\u0436\u0434\u044c",
            "500":"\u043b\u0435\u0433\u043a\u0438\u0439 \u0434\u043e\u0436\u0434\u044c",
            "501":"\u0434\u043e\u0436\u0434\u044c",
            "502":"\u0441\u0438\u043b\u044c\u043d\u044b\u0439 \u0434\u043e\u0436\u0434\u044c",
            "503":"\u043f\u0440\u043e\u043b\u0438\u0432\u043d\u043e\u0439 \u0434\u043e\u0436\u0434\u044c",
            "504":"\u0441\u0438\u043b\u044c\u043d\u044b\u0439 \u0434\u043e\u0436\u0434\u044c",
            "511":"\u0445\u043e\u043b\u043e\u0434\u043d\u044b\u0439 \u0434\u043e\u0436\u0434\u044c",
            "520":"\u0434\u043e\u0436\u0434\u044c",
            "521":"\u0434\u043e\u0436\u0434\u044c",
            "522":"\u0441\u0438\u043b\u044c\u043d\u044b\u0439 \u0434\u043e\u0436\u0434\u044c",
            "600":"\u043d\u0435\u0431\u043e\u043b\u044c\u0448\u043e\u0439 \u0441\u043d\u0435\u0433\u043e\u043f\u0430\u0434",
            "601":"\u0441\u043d\u0435\u0433\u043e\u043f\u0430\u0434",
            "602":"\u0441\u0438\u043b\u044c\u043d\u044b\u0439 \u0441\u043d\u0435\u0433\u043e\u043f\u0430\u0434",
            "611":"\u0441\u043b\u044f\u043a\u043e\u0442\u044c",
            "621":"\u0441\u043d\u0435\u0433\u043e\u043f\u0430\u0434",
            "701":"\u0442\u0443\u043c\u0430\u043d",
            "711":"\u0442\u0443\u043c\u0430\u043d\u043d\u043e",
            "721":"\u0442\u0443\u043c\u0430\u043d\u043d\u043e",
            "731":"\u043f\u0435\u0441\u0447\u0430\u043d\u0430\u044f \u0431\u0443\u0440\u044f",
            "741":"\u0442\u0443\u043c\u0430\u043d\u043d\u043e",
            "800":"\u044f\u0441\u043d\u043e",
            "801":"\u043e\u0431\u043b\u0430\u0447\u043d\u043e",
            "802":"\u0441\u043b\u0435\u0433\u043a\u0430 \u043e\u0431\u043b\u0430\u0447\u043d\u043e",
            "803":"\u043f\u0430\u0441\u043c\u0443\u0440\u043d\u043e",
            "804":"\u043f\u0430\u0441\u043c\u0443\u0440\u043d\u043e",
            "900":"\u0442\u043e\u0440\u043d\u0430\u0434\u043e",
            "901":"\u0442\u0440\u043e\u043f\u0438\u0447\u0435\u0441\u043a\u0430\u044f \u0431\u0443\u0440\u044f",
            "902":"\u0443\u0440\u0430\u0433\u0430\u043d",
            "903":"\u0445\u043e\u043b\u043e\u0434\u043d\u043e",
            "904":"\u0436\u0430\u0440\u0430",
            "905":"\u0432\u0435\u0442\u0440\u0435\u043d\u043d\u043e",
            "906":"\u0433\u0440\u0430\u0434",
            "950":"Setting",
            "951":"Calm",
            "952":"Light breeze",
            "953":"Gentle Breeze",
            "954":"Moderate breeze",
            "955":"Fresh Breeze",
            "956":"Strong breeze",
            "957":"High wind, near gale",
            "958":"Gale",
            "959":"Severe Gale",
            "960":"Storm",
            "961":"Violent Storm",
            "962":"Hurricane"
    }
},
    "it":{
    "name":"Italian",
        "main":"",
        "description":{
        "200":"temporale con pioggerella",
            "201":"temporale con pioggia",
            "202":"temporale con pioggia forte",
            "210":"temporale",
            "211":"temporale",
            "212":"temporale forte",
            "221":"temporale",
            "230":"temporale con pioggerella",
            "231":"temporale con pioggerella",
            "232":"temporale con pioggerella",
            "300":"pioggerella",
            "301":"pioggerella",
            "302":"pioggerella",
            "310":"pioggerella",
            "311":"pioggerella",
            "312":"forte pioggerella",
            "321":"acquazzone",
            "500":"pioggia leggera",
            "501":"pioggia moderata",
            "502":"forte pioggia",
            "503":"pioggia fortissima",
            "504":"pioggia estrema",
            "511":"pioggia gelata",
            "520":"pioggerella",
            "521":"acquazzone",
            "522":"acquazzone",
            "600":"neve",
            "601":"neve",
            "602":"forte nevicata",
            "611":"nevischio",
            "621":"forte nevicata",
            "701":"foschia",
            "711":"fumo",
            "721":"foschia",
            "731":"mulinelli di sabbia\/polvere",
            "741":"nebbia",
            "800":"cielo sereno",
            "801":"poche nuvole",
            "802":"nubi sparse",
            "803":"nubi sparse",
            "804":"cielo coperto",
            "900":"tornado",
            "901":"tempesta tropicale",
            "902":"uragano",
            "903":"freddo",
            "904":"caldo",
            "905":"ventoso",
            "906":"grandine",
            "950":"Setting",
            "951":"Calmo",
            "952":"Bava di vento",
            "953":"Brezza leggera",
            "954":"Brezza tesa",
            "955":"Fresh Breeze",
            "956":"Strong breeze",
            "957":"High wind, near gale",
            "958":"Gale",
            "959":"Severe Gale",
            "960":"Tempesta",
            "961":"Tempesta violenta",
            "962":"Uragano"
    }
},
    "sp":{
    "name":"Spanish",
        "main":"",
        "description":{
        "200":"tormenta con lluvia ligera",
            "201":"tormenta con lluvia",
            "202":"tormenta con lluvia intensa",
            "210":"ligera tormenta",
            "211":"tormenta",
            "212":"fuerte tormenta",
            "221":"tormenta irregular",
            "230":"tormenta con llovizna ligera",
            "231":"tormenta con llovizna",
            "232":"tormenta con llovizna intensa",
            "300":"llovizna ligera",
            "301":"llovizna",
            "302":"llovizna de gran intensidad",
            "310":"lluvia y llovizna ligera",
            "311":"lluvia y llovizna",
            "312":"lluvia y llovizna de gran intensidad",
            "321":"chubasco",
            "500":"lluvia ligera",
            "501":"lluvia moderada",
            "502":"lluvia de gran intensidad",
            "503":"lluvia muy fuerte",
            "504":"lluvia muy fuerte",
            "511":"lluvia helada",
            "520":"chubasco de ligera intensidad",
            "521":"chubasco",
            "522":"chubasco de gran intensidad",
            "600":"nevada ligera",
            "601":"nieve",
            "602":"nevada intensa",
            "611":"aguanieve",
            "621":"chubasco de nieve",
            "701":"niebla",
            "711":"humo",
            "721":"niebla",
            "731":"torbellinos de arena\/polvo",
            "741":"bruma",
            "800":"cielo claro",
            "801":"algo de nubes",
            "802":"nubes dispersas",
            "803":"nubes rotas",
            "804":"nubes",
            "900":"tornado",
            "901":"tormenta tropical",
            "902":"hurac\u00e1n",
            "903":"fr\u00edo",
            "904":"calor",
            "905":"ventoso",
            "906":"granizo",
            "950":"Setting",
            "951":"calma",
            "952":"Viento flojo",
            "953":"Viento suave",
            "954":"Viento moderado",
            "955":"Brisa",
            "956":"Viento fuerte",
            "957":"Viento fuerte, pr\u00f3ximo a vendaval",
            "958":"Vendaval",
            "959":"Vendaval fuerte",
            "960":"Tempestad",
            "961":"Tempestad violenta",
            "962":"Hurac\u00e1n"
    }
},
    "ua":{
    "name":"Ukrainian",
        "main":"",
        "description":{
        "200":"\u0433\u0440\u043e\u0437\u0430 \u0437 \u043b\u0435\u0433\u043a\u0438\u043c \u0434\u043e\u0449\u0435\u043c",
            "201":"\u0433\u0440\u043e\u0437\u0430 \u0437 \u0434\u043e\u0449\u0435\u043c",
            "202":"\u0433\u0440\u043e\u0437\u0430 \u0437\u0456 \u0437\u043b\u0438\u0432\u043e\u044e",
            "210":"\u043b\u0435\u0433\u043a\u0430 \u0433\u0440\u043e\u0437\u0430",
            "211":"\u0433\u0440\u043e\u0437\u0430",
            "212":"\u0441\u0438\u043b\u044c\u043d\u0430 \u0433\u0440\u043e\u0437\u0430",
            "221":"\u043a\u043e\u0440\u043e\u0442\u043a\u043e\u0447\u0430\u0441\u043d\u0456 \u0433\u0440\u043e\u0437\u0438",
            "230":"\u0433\u0440\u043e\u0437\u0430 \u0437 \u0434\u0440\u0456\u0431\u043d\u0438\u043c \u0434\u043e\u0449\u0435\u043c",
            "231":"\u0433\u0440\u043e\u0437\u0430 \u0437 \u0434\u043e\u0449\u0435\u043c",
            "232":"\u0433\u0440\u043e\u0437\u0430 \u0437 \u0441\u0438\u043b\u044c\u043d\u0438\u043c \u0434\u0440\u0456\u0431\u043d\u0438\u043c \u0434\u043e\u0449\u0435\u043c",
            "300":"\u043b\u0435\u0433\u043a\u0430 \u043c\u0440\u044f\u043a\u0430",
            "301":"\u043c\u0440\u044f\u043a\u0430",
            "302":"\u0441\u0438\u043b\u044c\u043d\u0430 \u043c\u0440\u044f\u043a\u0430",
            "310":"\u043b\u0435\u0433\u043a\u0438\u0439 \u0434\u0440\u0456\u0431\u043d\u0438\u0439 \u0434\u043e\u0449",
            "311":"\u0434\u0440\u0456\u0431\u043d\u0438\u0439 \u0434\u043e\u0449",
            "312":"\u0441\u0438\u043b\u044c\u043d\u0438\u0439 \u0434\u0440\u0456\u0431\u043d\u0438\u0439 \u0434\u043e\u0449",
            "321":"\u0434\u0440\u0456\u0431\u043d\u0438\u0439 \u0434\u043e\u0449",
            "500":"\u043b\u0435\u0433\u043a\u0430 \u0437\u043b\u0438\u0432\u0430",
            "501":"\u043f\u043e\u043c\u0456\u0440\u043d\u0438\u0439 \u0434\u043e\u0449",
            "502":"\u0441\u0438\u043b\u044c\u043d\u0438\u0439 \u0434\u043e\u0449",
            "503":"\u0441\u0438\u043b\u044c\u043d\u0430 \u0437\u043b\u0438\u0432\u0430",
            "504":"\u0437\u043b\u0438\u0432\u0430",
            "511":"\u043a\u0440\u0438\u0436\u0430\u043d\u0438\u0439 \u0434\u043e\u0449",
            "520":"\u0434\u043e\u0449",
            "521":"\u0434\u043e\u0449",
            "522":"\u0441\u0438\u043b\u044c\u043d\u0430 \u0437\u043b\u0438\u0432\u0430",
            "600":"\u043b\u0435\u0433\u043a\u0438\u0439 \u0441\u043d\u0456\u0433\u043e\u043f\u0430\u0434",
            "601":"\u0441\u043d\u0456\u0433 ",
            "602":"\u0441\u0438\u043b\u044c\u043d\u0438\u0439 \u0441\u043d\u0456\u0433\u043e\u043f\u0430\u0434",
            "611":"\u043c\u043e\u043a\u0440\u0438\u0439 \u0441\u043d\u0456\u0433",
            "621":"\u0441\u043d\u0456\u0433\u043e\u043f\u0430\u0434",
            "701":"\u0442\u0443\u043c\u0430\u043d",
            "711":"\u0442\u0443\u043c\u0430\u043d",
            "721":"\u0441\u0435\u0440\u043f\u0430\u043d\u043e\u043a",
            "731":"\u043f\u0456\u0449\u0430\u043d\u0430 \u0437\u0430\u043c\u0435\u0442\u0456\u043b\u044c",
            "741":"\u0442\u0443\u043c\u0430\u043d",
            "800":"\u0447\u0438\u0441\u0442\u0435 \u043d\u0435\u0431\u043e",
            "801":"\u0442\u0440\u043e\u0445\u0438 \u0445\u043c\u0430\u0440\u043d\u043e",
            "802":"\u0440\u043e\u0437\u0456\u0440\u0432\u0430\u043d\u0456 \u0445\u043c\u0430\u0440\u0438",
            "803":"\u0445\u043c\u0430\u0440\u043d\u043e",
            "804":"\u0445\u043c\u0430\u0440\u043d\u043e",
            "900":"\u0442\u043e\u0440\u043d\u0430\u0434\u043e",
            "901":"\u0442\u0440\u043e\u043f\u0456\u0447\u043d\u0430 \u0431\u0443\u0440\u044f",
            "902":"\u0431\u0443\u0440\u0435\u0432\u0456\u0439",
            "903":"\u0445\u043e\u043b\u043e\u0434\u043d\u043e",
            "904":"\u0441\u043f\u0435\u043a\u0430",
            "905":"\u0432\u0456\u0442\u0440\u044f\u043d\u043e",
            "906":"\u0433\u0440\u0430\u0434",
            "950":"Setting",
            "951":"Calm",
            "952":"Light breeze",
            "953":"Gentle Breeze",
            "954":"Moderate breeze",
            "955":"Fresh Breeze",
            "956":"Strong breeze",
            "957":"High wind, near gale",
            "958":"Gale",
            "959":"Severe Gale",
            "960":"Storm",
            "961":"Violent Storm",
            "962":"Hurricane"
    }
},
    "de":{
    "name":"German",
        "main":"",
        "description":{
        "200":"Gewitter mit leichtem Regen",
            "201":"Gewitter mit Regen",
            "202":"Gewitter mit starkem Regen",
            "210":"leichte Gewitter",
            "211":"Gewitter",
            "212":"schwere Gewitter",
            "221":"einige Gewitter",
            "230":"Gewitter mit leichtem Nieselregen",
            "231":"Gewitter mit Nieselregen",
            "232":"Gewitter mit starkem Nieselregen",
            "300":"leichtes Nieseln",
            "301":"Nieseln",
            "302":"starkes Nieseln",
            "310":"leichter Nieselregen",
            "311":"Nieselregen",
            "312":"starker Nieselregen",
            "321":"Nieselschauer",
            "500":"leichter Regen",
            "501":"m\u00e4\u00dfiger Regen",
            "502":"sehr starker Regen",
            "503":"sehr starker Regen",
            "504":"Starkregen",
            "511":"Eisregen",
            "520":"leichte Regenschauer",
            "521":"Regenschauer",
            "522":"heftige Regenschauer",
            "600":"m\u00e4\u00dfiger Schnee",
            "601":"Schnee",
            "602":"heftiger Schneefall",
            "611":"Graupel",
            "621":"Schneeschauer",
            "701":"tr\u00fcb",
            "711":"Rauch",
            "721":"Dunst",
            "731":"Sand \/ Staubsturm",
            "741":"Nebel",
            "800":"klarer Himmel",
            "801":"ein paar Wolken",
            "802":"\u00fcberwiegend bew\u00f6lkt",
            "803":"\u00fcberwiegend bew\u00f6lkt",
            "804":"wolkenbedeckt",
            "900":"Tornado",
            "901":"Tropensturm",
            "902":"Hurrikan",
            "903":"kalt",
            "904":"hei\u00df",
            "905":"windig",
            "906":"Hagel",
            "950":"Setting",
            "951":"Windstille",
            "952":"Leichte Brise",
            "953":"Milde Brise",
            "954":"M\u00e4\u00dfige Brise",
            "955":"Frische Brise",
            "956":"Starke Brise",
            "957":"Hochwind, ann\u00e4hender Sturm",
            "958":"Sturm",
            "959":"Schwerer Sturm",
            "960":"Gewitter",
            "961":"Heftiges Gewitter",
            "962":"Orkan"
    }
},
    "pt":{
    "name":"Portuguese",
        "main":"",
        "description":{
        "200":"trovoada com chuva leve",
            "201":"trovoada com chuva",
            "202":"trovoada com chuva forte",
            "210":"trovoada leve",
            "211":"trovoada",
            "212":"trovoada pesada",
            "221":"trovoada irregular",
            "230":"trovoada com garoa fraca",
            "231":"trovoada com garoa",
            "232":"trovoada com garoa pesada",
            "300":"garoa fraca",
            "301":"garoa",
            "302":"garoa intensa",
            "310":"chuva leve",
            "311":"chuva fraca",
            "312":"chuva forte",
            "321":"chuva de garoa",
            "500":"chuva fraca",
            "501":"Chuva moderada",
            "502":"chuva de intensidade pesado",
            "503":"chuva muito forte",
            "504":"Chuva Forte",
            "511":"chuva com congelamento",
            "520":"chuva moderada",
            "521":"chuva",
            "522":"chuva de intensidade pesada",
            "600":"Neve branda",
            "601":"neve",
            "602":"Neve pesada",
            "611":"chuva com neve",
            "621":"banho de neve",
            "701":"N\u00e9voa",
            "711":"fuma\u00e7a",
            "721":"neblina",
            "731":"turbilh\u00f5es de areia\/poeira",
            "741":"Neblina",
            "800":"c\u00e9u claro",
            "801":"Algumas nuvens",
            "802":"nuvens dispersas",
            "803":"nuvens quebrados",
            "804":"tempo nublado",
            "900":"tornado",
            "901":"tempestade tropical",
            "902":"furac\u00e3o",
            "903":"frio",
            "904":"quente",
            "905":"com vento",
            "906":"granizo",
            "950":"Setting",
            "951":"Calm",
            "952":"Light breeze",
            "953":"Gentle Breeze",
            "954":"Moderate breeze",
            "955":"Fresh Breeze",
            "956":"Strong breeze",
            "957":"High wind, near gale",
            "958":"Gale",
            "959":"Severe Gale",
            "960":"Storm",
            "961":"Violent Storm",
            "962":"Hurricane"
    }
},
    "ro":{
    "name":"Romanian",
        "main":"",
        "description":{
        "200":"furtun\u0103 cu ploaie u\u0219oar\u0103",
            "201":"furtun\u0103",
            "202":"furtun\u0103 cu ploaie puternic\u0103",
            "210":"furtun\u0103 u\u0219oar\u0103",
            "211":"furtun\u0103",
            "212":"furtun\u0103 puternic\u0103",
            "221":"furtun\u0103 aprig\u0103",
            "230":"furtun\u0103 cu burni\u021b\u0103",
            "231":"furtun\u0103 cu burni\u021b\u0103",
            "232":"furtun\u0103 cu burni\u021b\u0103",
            "300":"burni\u021b\u0103 de intensitate joas\u0103",
            "301":"burni\u021b\u0103",
            "302":"burni\u021b\u0103 de intensitate mare",
            "310":"burni\u021b\u0103 de intensitate joas\u0103",
            "311":"burni\u021b\u0103",
            "312":"burni\u021b\u0103 de intensitate mare",
            "321":"burni\u021b\u0103",
            "500":"ploaie u\u0219oar\u0103",
            "501":"ploaie",
            "502":"ploaie puternic\u0103",
            "503":"ploaie toren\u021bial\u0103 ",
            "504":"ploaie extrem\u0103",
            "511":"ploaie \u00eenghe\u021bat\u0103",
            "520":"ploaie de scurt\u0103 durat\u0103",
            "521":"ploaie de scurt\u0103 durat\u0103",
            "522":"ploaie de scurt\u0103 durat\u0103",
            "600":"ninsoare u\u0219oar\u0103",
            "601":"ninsoare",
            "602":"ninsoare puternic\u0103",
            "611":"lapovi\u021b\u0103",
            "621":"ninsoare de scurt\u0103 durat\u0103",
            "701":"cea\u021b\u0103",
            "711":"cea\u021b\u0103",
            "721":"cea\u021b\u0103",
            "731":"v\u00e2rtejuri de nisip",
            "741":"cea\u021b\u0103",
            "800":"cer senin",
            "801":"c\u00e2\u021biva nori",
            "802":"nori \u00eempr\u0103\u0219tia\u021bi",
            "803":"cer fragmentat",
            "804":"cer acoperit de nori",
            "900":"tornad\u0103",
            "901":"furtuna tropical\u0103",
            "902":"uragan",
            "903":"rece",
            "904":"fierbinte",
            "905":"vant puternic",
            "906":"grindin\u0103",
            "950":"Setting",
            "951":"Calm",
            "952":"Light breeze",
            "953":"Gentle Breeze",
            "954":"Moderate breeze",
            "955":"Fresh Breeze",
            "956":"Strong breeze",
            "957":"High wind, near gale",
            "958":"Gale",
            "959":"Severe Gale",
            "960":"Storm",
            "961":"Violent Storm",
            "962":"Hurricane"
    }
},
    "pl":{
    "name":"Polish",
        "main":"",
        "description":{
        "200":"Burza z lekkimi opadami deszczu",
            "201":"Burza z opadami deszczu",
            "202":"Burza z intensywnymi opadami deszczu",
            "210":"Lekka burza",
            "211":"Burza",
            "212":"Silna burza",
            "221":"Burza",
            "230":"Burza z lekk\u0105 m\u017cawk\u0105",
            "231":"Burza z m\u017cawka",
            "232":"Burza z intensywn\u0105 m\u017cawk\u0105",
            "300":"Lekka m\u017cawka",
            "301":"M\u017cawka",
            "302":"Intensywna m\u017cawka",
            "310":"Lekkie opady drobnego deszczu",
            "311":"Deszcz \/ m\u017cawka",
            "312":"Intensywny deszcz \/ m\u017cawka",
            "321":"Silna m\u017cawka",
            "500":"Lekki deszcz",
            "501":"Umiarkowany deszcz",
            "502":"Intensywny deszcz",
            "503":"bardzo silny deszcz",
            "504":"Ulewa",
            "511":"Marzn\u0105cy deszcz",
            "520":"Kr\u00f3tka ulewa",
            "521":"Deszcz",
            "522":"Intensywny, lekki deszcz",
            "600":"Lekkie opady \u015bniegu",
            "601":"\u015anieg",
            "602":"Mocne opady \u015bniegu",
            "611":"Deszcz ze \u015bniegem",
            "621":"\u015anie\u017cyca",
            "701":"Mgie\u0142ka",
            "711":"Smog",
            "721":"Zamglenia",
            "731":"Zamie\u0107 piaskowa",
            "741":"Mg\u0142a",
            "800":"Bezchmurnie",
            "801":"Lekkie zachmurzenie",
            "802":"Rozproszone chmury",
            "803":"Pochmurno z przeja\u015bnieniami",
            "804":"Pochmurno",
            "900":"tornado",
            "901":"burza tropikalna",
            "902":"Huragan",
            "903":"Ch\u0142odno",
            "904":"Gor\u0105co",
            "905":"wietrznie",
            "906":"Grad",
            "950":"Setting",
            "951":"Spokojnie",
            "952":"Lekka bryza",
            "953":"Delikatna bryza",
            "954":"Umiarkowana bryza",
            "955":"\u015awie\u017ca bryza",
            "956":"Silna bryza",
            "957":"Prawie wichura",
            "958":"Wichura",
            "959":"Silna wichura",
            "960":"Sztorm",
            "961":"Gwa\u0142towny sztorm",
            "962":"Huragan"
    }
},
    "fi":{
    "name":"Finnish",
        "main":"",
        "description":{
        "200":"ukkosmyrsky ja kevyt sade",
            "201":"ukkosmyrsky ja sade",
            "202":"ukkosmyrsky ja kova sade",
            "210":"pieni ukkosmyrsky",
            "211":"ukkosmyrsky",
            "212":"kova ukkosmyrsky",
            "221":"liev\u00e4 ukkosmyrsky",
            "230":"ukkosmyrsky ja kevyt tihkusade",
            "231":"ukkosmyrsky ja tihkusade",
            "232":"ukkosmyrsky ja kova tihkusade",
            "300":"liev\u00e4 tihuttainen",
            "301":"tihuttaa",
            "302":"kova tihuttainen",
            "310":"liev\u00e4 tihkusade",
            "311":"tihkusade",
            "312":"kova tihkusade",
            "321":"tihkusade",
            "500":"pieni sade",
            "501":"kohtalainen sade",
            "502":"kova sade",
            "503":"eritt\u00e4in runsasta sadetta",
            "504":"kova sade",
            "511":"j\u00e4\u00e4t\u00e4v\u00e4 sade",
            "520":"liev\u00e4 tihkusade",
            "521":"tihkusade",
            "522":"kova sade",
            "600":"pieni lumisade",
            "601":"lumi",
            "602":"paljon lunta",
            "611":"r\u00e4nt\u00e4",
            "621":"lumikuuro",
            "701":"sumu",
            "711":"savu",
            "721":"sumu",
            "731":"hiekka\/p\u00f6ly py\u00f6rre",
            "741":"sumu",
            "800":"taivas on selke\u00e4",
            "801":"v\u00e4h\u00e4n pilvi\u00e4",
            "802":"ajoittaisia pilvi\u00e4",
            "803":"hajanaisia pilvi\u00e4",
            "804":"pilvinen",
            "900":"tornado",
            "901":"trooppinen myrsky",
            "902":"hirmumyrsky",
            "903":"kylm\u00e4",
            "904":"kuuma",
            "905":"tuulinen",
            "906":"rakeita",
            "950":"Setting",
            "951":"Calm",
            "952":"Light breeze",
            "953":"Gentle Breeze",
            "954":"Moderate breeze",
            "955":"Fresh Breeze",
            "956":"Strong breeze",
            "957":"High wind, near gale",
            "958":"Gale",
            "959":"Severe Gale",
            "960":"Storm",
            "961":"Violent Storm",
            "962":"Hurricane"
    }
},
    "nl":{
    "name":"Dutch",
        "main":"",
        "description":{
        "200":"onweersbui met lichte regen",
            "201":"onweersbui met regen",
            "202":"onweersbui met zware regenval",
            "210":"lichte onweersbui",
            "211":"onweersbui",
            "212":"zware onweersbui",
            "221":"onregelmatig onweersbui",
            "230":"onweersbui met lichte motregen",
            "231":"onweersbui met motregen",
            "232":"onweersbui met zware motregen",
            "300":"lichte motregen",
            "301":"motregen",
            "302":"zware motregen",
            "310":"lichte motregen\/regen",
            "311":"motregen",
            "312":"zware motregen\/regen",
            "321":"zware motregen",
            "500":"lichte regen",
            "501":"matige regen",
            "502":"zware regenval",
            "503":"zeer zware regenval",
            "504":"extreme regen",
            "511":"Koude buien",
            "520":"lichte stortregen",
            "521":"stortregen",
            "522":"zware stortregen",
            "600":"lichte sneeuw",
            "601":"sneeuw",
            "602":"hevige sneeuw",
            "611":"ijzel",
            "621":"natte sneeuw",
            "701":"mist",
            "711":"mist",
            "721":"nevel",
            "731":"zand\/stof werveling",
            "741":"mist",
            "800":"onbewolkt",
            "801":"licht bewolkt",
            "802":"half bewolkt",
            "803":"zwaar bewolkt",
            "804":"geheel bewolkt",
            "900":"tornado",
            "901":"tropische storm",
            "902":"orkaan",
            "903":"koud",
            "904":"heet",
            "905":"stormachtig",
            "906":"hagel",
            "950":"Windstil",
            "951":"Kalm",
            "952":"Lichte bries",
            "953":"Zachte bries",
            "954":"Matige bries",
            "955":"Vrij krachtige wind",
            "956":"Krachtige wind",
            "957":"Harde wind",
            "958":"Stormachtig",
            "959":"Storm",
            "960":"Zware storm",
            "961":"Zeer zware storm",
            "962":"Orkaan"
    }
},
    "fr":{
    "name":"French",
        "main":"",
        "description":{
        "200":"orage et pluie fine",
            "201":"orage et pluie",
            "202":"orage et fortes pluies",
            "210":"orages l\u00e9gers",
            "211":"orages",
            "212":"gros orages",
            "221":"orages \u00e9parses",
            "230":"Orage avec l\u00e9g\u00e8re bruine",
            "231":"orages \u00e9parses",
            "232":"gros orage",
            "300":"Bruine l\u00e9g\u00e8re",
            "301":"Bruine",
            "302":"Fortes bruines",
            "310":"Pluie fine \u00e9parse",
            "311":"pluie fine",
            "312":"Crachin intense",
            "321":"Averses de bruine",
            "500":"l\u00e9g\u00e8res pluies",
            "501":"pluies mod\u00e9r\u00e9es",
            "502":"Fortes pluies",
            "503":"tr\u00e8s fortes pr\u00e9cipitations",
            "504":"grosses pluies",
            "511":"pluie vergla\u00e7ante",
            "520":"petites averses",
            "521":"averses de pluie",
            "522":"averses intenses",
            "600":"l\u00e9g\u00e8res neiges",
            "601":"neige",
            "602":"fortes chutes de neige",
            "611":"neige fondue",
            "621":"averses de neige",
            "701":"brume",
            "711":"Brouillard",
            "721":"brume",
            "731":"temp\u00eates de sable",
            "741":"brouillard",
            "800":"ensoleill\u00e9",
            "801":"peu nuageux",
            "802":"partiellement ensoleill\u00e9",
            "803":"nuageux",
            "804":"Couvert",
            "900":"tornade",
            "901":"temp\u00eate tropicale",
            "902":"ouragan",
            "903":"froid",
            "904":"chaud",
            "905":"venteux",
            "906":"gr\u00eale",
            "950":"Setting",
            "951":"Calme",
            "952":"Brise l\u00e9g\u00e8re",
            "953":"Brise douce",
            "954":"Brise mod\u00e9r\u00e9e",
            "955":"Brise fraiche",
            "956":"Brise forte",
            "957":"Vent fort, presque violent",
            "958":"Vent violent",
            "959":"Vent tr\u00e8s violent",
            "960":"Temp\u00eate",
            "961":"emp\u00eate violente",
            "962":"Ouragan"
    }
},
    "bg":{
    "name":"Bulgarian",
        "main":"",
        "description":{
        "200":"\u0413\u0440\u044a\u043c\u043e\u0442\u0435\u0432\u0438\u0447\u043d\u0430 \u0431\u0443\u0440\u044f \u0441\u044a\u0441 \u0441\u043b\u0430\u0431 \u0434\u044a\u0436\u0434",
            "201":"\u0413\u0440\u044a\u043c\u043e\u0442\u0435\u0432\u0438\u0447\u043d\u0430 \u0431\u0443\u0440\u044f \u0441 \u0432\u0430\u043b\u0435\u0436",
            "202":"\u0413\u0440\u044a\u043c\u043e\u0442\u0435\u0432\u0438\u0447\u043d\u0430 \u0431\u0443\u0440\u044f \u0441 \u043f\u043e\u0440\u043e\u0439",
            "210":"\u0421\u043b\u0430\u0431\u0430 \u0433\u0440\u044a\u043c\u043e\u0442\u0435\u0432\u0438\u0447\u043d\u0430 \u0431\u0443\u0440\u044f",
            "211":"\u0413\u0440\u044a\u043c\u043e\u0442\u0435\u0432\u0438\u0447\u043d\u0430 \u0431\u0443\u0440\u044f",
            "212":"\u0421\u0438\u043b\u043d\u0430 \u0433\u0440\u044a\u043c\u043e\u0442\u0435\u0432\u0438\u0447\u043d\u0430 \u0431\u0443\u0440\u044f",
            "221":"\u0420\u0430\u0437\u043a\u044a\u0441\u0430\u043d\u0430 \u043e\u0431\u043b\u0430\u0447\u043d\u043e\u0441\u0442",
            "230":"\u0413\u0440\u044a\u043c\u043e\u0442\u0435\u0432\u0438\u0447\u043d\u0430 \u0431\u0443\u0440\u044f \u0441\u044a\u0441 \u0441\u043b\u0430\u0431 \u0440\u044a\u043c\u0435\u0436",
            "231":"\u0413\u0440\u044a\u043c\u043e\u0442\u0435\u0432\u0438\u0447\u043d\u0430 \u0431\u0443\u0440\u044f \u0441 \u0440\u044a\u043c\u0435\u0436",
            "232":"\u0413\u0440\u044a\u043c\u043e\u0442\u0435\u0432\u0438\u0447\u043d\u0430 \u0431\u0443\u0440\u044f \u0441\u044a\u0441 \u0441\u0438\u043b\u0435\u043d \u0440\u044a\u043c\u0435\u0436",
            "300":"\u0421\u043b\u0430\u0431 \u0440\u044a\u043c\u0435\u0436",
            "301":"\u0420\u044a\u043c\u0438",
            "302":"\u0421\u0438\u043b\u0435\u043d \u0440\u044a\u043c\u0435\u0436",
            "310":"\u0421\u043b\u0430\u0431 \u0434\u044a\u0436\u0434",
            "311":"\u0420\u044a\u043c\u044f\u0449 \u0434\u044a\u0436\u0434",
            "312":"\u0421\u0438\u043b\u0435\u043d \u0440\u044a\u043c\u0435\u0436",
            "321":"\u0421\u0438\u043b\u0435\u043d \u0440\u044a\u043c\u0435\u0436",
            "500":"\u0421\u043b\u0430\u0431 \u0434\u044a\u0436\u0434",
            "501":"\u0423\u043c\u0435\u0440\u0435\u043d \u0434\u044a\u0436\u0434",
            "502":"\u0421\u0438\u043b\u0435\u043d \u0434\u044a\u0436\u0434",
            "503":"\u041c\u043d\u043e\u0433\u043e \u0441\u0438\u043b\u0435\u043d \u0432\u0430\u043b\u0435\u0436",
            "504":"\u0421\u0438\u043b\u0435\u043d \u0434\u044a\u0436\u0434",
            "511":"\u0414\u044a\u0436\u0434 \u0441\u044a\u0441 \u0441\u0442\u0443\u0434",
            "520":"\u0421\u043b\u0430\u0431 \u0434\u044a\u0436\u0434",
            "521":"\u041e\u0431\u0438\u043b\u0435\u043d \u0434\u044a\u0436\u0434",
            "522":"\u041f\u043e\u0440\u043e\u0439",
            "600":"\u0421\u043b\u0430\u0431 \u0441\u043d\u0435\u0433\u043e\u0432\u0430\u043b\u0435\u0436",
            "601":"\u0421\u043d\u0435\u0433\u043e\u0432\u0430\u043b\u0435\u0436",
            "602":"\u0421\u0438\u043b\u0435\u043d \u0441\u043d\u0435\u0433\u043e\u0432\u0430\u043b\u0435\u0436",
            "611":"\u0418\u0437\u043d\u0435\u043d\u0430\u0434\u0432\u0430\u0449 \u0432\u0430\u043b\u0435\u0436",
            "621":"\u041e\u0431\u0438\u043b\u0435\u043d \u0441\u043d\u0435\u0433\u043e\u0432\u0430\u043b\u0435\u0436",
            "701":"\u041c\u044a\u0433\u043b\u0430",
            "711":"\u0414\u0438\u043c",
            "721":"\u041d\u0438\u0441\u043a\u0430 \u043c\u044a\u0433\u043b\u0430",
            "731":"\u041f\u044f\u0441\u044a\u0447\u043d\u0430\/\u041f\u0440\u0430\u0448\u043d\u0430 \u0431\u0443\u0440\u044f",
            "741":"\u041c\u044a\u0433\u043b\u0430",
            "800":"\u042f\u0441\u043d\u043e \u043d\u0435\u0431\u0435",
            "801":"\u041d\u0438\u0441\u043a\u0430 \u043e\u0431\u043b\u0430\u0447\u043d\u043e\u0441\u0442",
            "802":"\u0420\u0430\u0437\u043a\u044a\u0441\u0430\u043d\u0430 \u043e\u0431\u043b\u0430\u0447\u043d\u043e\u0441\u0442",
            "803":"\u0420\u0430\u0437\u0441\u0435\u044f\u043d\u0430 \u043e\u0431\u043b\u0430\u0447\u043d\u043e\u0441\u0442",
            "804":"\u0422\u044a\u043c\u043d\u0438 \u043e\u0431\u043b\u0430\u0446\u0438",
            "900":"\u0422\u043e\u0440\u043d\u0430\u0434\u043e\/\u0423\u0440\u0430\u0433\u0430\u043d",
            "901":"\u0422\u0440\u043e\u043f\u0438\u0447\u0435\u0441\u043a\u0430 \u0431\u0443\u0440\u044f",
            "902":"\u0423\u0440\u0430\u0433\u0430\u043d",
            "903":"\u0421\u0442\u0443\u0434\u0435\u043d\u043e",
            "904":"\u0413\u043e\u0440\u0435\u0449\u043e \u0432\u0440\u0435\u043c\u0435",
            "905":"\u0412\u0435\u0442\u0440\u043e\u0432\u0438\u0442\u043e",
            "906":"\u0413\u0440\u0430\u0434",
            "950":"Setting",
            "951":"Calm",
            "952":"Light breeze",
            "953":"Gentle Breeze",
            "954":"Moderate breeze",
            "955":"Fresh Breeze",
            "956":"Strong breeze",
            "957":"High wind, near gale",
            "958":"Gale",
            "959":"Severe Gale",
            "960":"Storm",
            "961":"Violent Storm",
            "962":"Hurricane"
    }
},
    "se":{
    "name":"Swedish",
        "main":"",
        "description":{
        "200":"\u00e5skov\u00e4der",
            "201":"\u00e5skov\u00e4der",
            "202":"fullt \u00e5skov\u00e4der",
            "210":"\u00e5ska",
            "211":"\u00e5skov\u00e4der",
            "212":"\u00e5ska",
            "221":"oj\u00e4mnt ov\u00e4der",
            "230":"\u00e5skov\u00e4der",
            "231":"\u00e5skov\u00e4der",
            "232":"fullt \u00e5skov\u00e4der",
            "300":"mjukt duggregn",
            "301":"duggregn",
            "302":"h\u00e5rt duggregn",
            "310":"mjukt regn",
            "311":"regn",
            "312":"h\u00e5rt regn",
            "321":"duggregn",
            "500":"mjukt regn",
            "501":"M\u00e5ttlig regn",
            "502":"h\u00e5rt regn",
            "503":"mycket kraftigt regn",
            "504":"\u00f6sregn",
            "511":"underkylt regn",
            "520":"mjukt \u00f6sregn",
            "521":"dusch-regn",
            "522":"regnar sm\u00e5spik",
            "600":"mjuk sn\u00f6",
            "601":"sn\u00f6",
            "602":"kraftigt sn\u00f6fall",
            "611":"sn\u00f6blandat regn",
            "621":"sn\u00f6ov\u00e4der",
            "701":"dimma",
            "711":"smogg",
            "721":"dis",
            "731":"sandstorm",
            "741":"dimmigt",
            "800":"klar himmel",
            "801":"n\u00e5gra moln",
            "802":"spridda moln",
            "803":"molnigt",
            "804":"\u00f6verskuggande moln",
            "900":"storm",
            "901":"tropisk storm",
            "902":"orkan",
            "903":"kallt",
            "904":"varmt",
            "905":"bl\u00e5sigt",
            "906":"hagel",
            "950":"Setting",
            "951":"Calm",
            "952":"Light breeze",
            "953":"Gentle Breeze",
            "954":"Moderate breeze",
            "955":"Fresh Breeze",
            "956":"Strong breeze",
            "957":"High wind, near gale",
            "958":"Gale",
            "959":"Severe Gale",
            "960":"Storm",
            "961":"Violent Storm",
            "962":"Hurricane"
    }
},
    "zh_tw":{
    "name":"Chinese Traditional",
        "main":"",
        "description":{
        "200":"\u96f7\u9663\u96e8",
            "201":"\u96f7\u9663\u96e8",
            "202":"\u96f7\u9663\u96e8",
            "210":"\u96f7\u9663\u96e8",
            "211":"\u96f7\u9663\u96e8",
            "212":"\u96f7\u9663\u96e8",
            "221":"\u96f7\u9663\u96e8",
            "230":"\u96f7\u9663\u96e8",
            "231":"\u96f7\u9663\u96e8",
            "232":"\u96f7\u9663\u96e8",
            "300":"\u5c0f\u96e8",
            "301":"\u5c0f\u96e8",
            "302":"\u5927\u96e8",
            "310":"\u5c0f\u96e8",
            "311":"\u5c0f\u96e8",
            "312":"\u5927\u96e8",
            "321":"\u9663\u96e8",
            "500":"\u5c0f\u96e8",
            "501":"\u4e2d\u96e8",
            "502":"\u5927\u96e8",
            "503":"\u5927\u96e8",
            "504":"\u66b4\u96e8",
            "511":"\u51cd\u96e8",
            "520":"\u9663\u96e8",
            "521":"\u9663\u96e8",
            "522":"\u5927\u96e8",
            "600":"\u5c0f\u96ea",
            "601":"\u96ea",
            "602":"\u5927\u96ea",
            "611":"\u96e8\u593e\u96ea",
            "621":"\u9663\u96ea",
            "701":"\u8584\u9727",
            "711":"\u7159\u9727",
            "721":"\u8584\u9727",
            "731":"\u6c99\u65cb\u98a8",
            "741":"\u5927\u9727",
            "800":"\u6674",
            "801":"\u6674\uff0c\u5c11\u96f2",
            "802":"\u591a\u96f2",
            "803":"\u591a\u96f2",
            "804":"\u9670\uff0c\u591a\u96f2",
            "900":"\u9f8d\u6372\u98a8",
            "901":"\u71b1\u5e36\u98a8\u66b4",
            "902":"\u98b6\u98a8",
            "903":"\u51b7",
            "904":"\u71b1",
            "905":"\u5927\u98a8",
            "906":"\u51b0\u96f9",
            "950":"Setting",
            "951":"Calm",
            "952":"Light breeze",
            "953":"Gentle Breeze",
            "954":"Moderate breeze",
            "955":"Fresh Breeze",
            "956":"Strong breeze",
            "957":"High wind, near gale",
            "958":"Gale",
            "959":"Severe Gale",
            "960":"Storm",
            "961":"Violent Storm",
            "962":"Hurricane"
    }
},
    "tr":{
    "name":"Turkish",
        "main":"",
        "description":{
        "200":"G\u00f6k g\u00fcr\u00fclt\u00fcl\u00fc hafif ya\u011fmurlu",
            "201":"G\u00f6k g\u00fcr\u00fclt\u00fcl\u00fc ya\u011fmurlu",
            "202":"G\u00f6k g\u00fcr\u00fclt\u00fcl\u00fc sa\u011fanak ya\u011f\u0131\u015fl\u0131",
            "210":"Hafif sa\u011fanak",
            "211":"Sa\u011fanak",
            "212":"\u015eiddetli sa\u011fanak",
            "221":"Aral\u0131kl\u0131 sa\u011fanak",
            "230":"G\u00f6k g\u00fcr\u00fclt\u00fcl\u00fc hafif ya\u011f\u0131\u015fl\u0131",
            "231":"G\u00f6k g\u00fcr\u00fclt\u00fcl\u00fc ya\u011f\u0131\u015fl\u0131",
            "232":"G\u00f6k g\u00fcr\u00fclt\u00fcl\u00fc \u015fiddetli ya\u011f\u0131\u015fl\u0131",
            "300":"Yer yer hafif yo\u011funluklu ya\u011f\u0131\u015f",
            "301":"Yer yer ya\u011f\u0131\u015fl\u0131",
            "302":"Yer yer yo\u011fun ya\u011f\u0131\u015fl\u0131",
            "310":"Yer yer hafif ya\u011f\u0131\u015fl\u0131",
            "311":"Yer yer ya\u011f\u0131\u015fl\u0131",
            "312":"Yer yer yo\u011fun ya\u011f\u0131\u015fl\u0131",
            "321":"Yer yer sa\u011fanak ya\u011f\u0131\u015fl\u0131",
            "500":"Hafif ya\u011fmur",
            "501":"Orta \u015fiddetli ya\u011fmur",
            "502":"\u015eiddetli ya\u011fmur",
            "503":"\u00c7ok \u015fiddetli ya\u011fmur",
            "504":"A\u015f\u0131r\u0131 ya\u011fmur",
            "511":"Ya\u011f\u0131\u015fl\u0131 ve so\u011fuk hava",
            "520":"K\u0131sa s\u00fcreli hafif yo\u011funluklu ya\u011fmur",
            "521":"K\u0131sa s\u00fcreli ya\u011fmur",
            "522":"K\u0131sa s\u00fcreli \u015fiddetli ya\u011fmur",
            "600":"Hafif kar ya\u011f\u0131\u015fl\u0131",
            "601":"Kar ya\u011f\u0131\u015fl\u0131",
            "602":"Yo\u011fun kar ya\u011f\u0131\u015fl\u0131",
            "611":"Karla kar\u0131\u015f\u0131k ya\u011fmurlu",
            "621":"K\u0131sa s\u00fcrel\u00fc kar ya\u011f\u0131\u015f\u0131",
            "701":"Sisli",
            "711":"Sisli",
            "721":"Hafif sisli",
            "731":"Kum\/Toz f\u0131rt\u0131nas\u0131",
            "741":"Sisli",
            "800":"A\u00e7\u0131k",
            "801":"Az bulutlu",
            "802":"Par\u00e7al\u0131 az bulutlu",
            "803":"Par\u00e7al\u0131 bulutlu",
            "804":"Kapal\u0131",
            "900":"Kas\u0131rga",
            "901":"Tropik f\u0131rt\u0131na",
            "902":"Hortum",
            "903":"\u00c7ok So\u011fuk",
            "904":"\u00c7ok S\u0131cak",
            "905":"R\u00fczgarl\u0131",
            "906":"Dolu ya\u011f\u0131\u015f\u0131",
            "950":"Durgun",
            "951":"Sakin",
            "952":"Hafif R\u00fczgarl\u0131",
            "953":"Az R\u00fczgarl\u0131",
            "954":"Orta Seviye R\u00fczgarl\u0131",
            "955":"R\u00fczgarl\u0131",
            "956":"Kuvvetli R\u00fczgar",
            "957":"Sert R\u00fczgar",
            "958":"F\u0131rt\u0131na",
            "959":"\u015eiddetli F\u0131rt\u0131na",
            "960":"Kas\u0131rga",
            "961":"\u015eiddetli Kas\u0131rga",
            "962":"\u00c7ok \u015eiddetli Kas\u0131rga"
    }
},
    "zh_cn":{
    "name":"Chinese Simplified",
        "main":"",
        "description":{
        "200":"\u96f7\u9635\u96e8",
            "201":"\u96f7\u9635\u96e8",
            "202":"\u96f7\u9635\u96e8",
            "210":"\u96f7\u9635\u96e8",
            "211":"\u96f7\u9635\u96e8",
            "212":"\u96f7\u9635\u96e8",
            "221":"\u96f7\u9635\u96e8",
            "230":"\u96f7\u9635\u96e8",
            "231":"\u96f7\u9635\u96e8",
            "232":"\u96f7\u9635\u96e8",
            "300":"\u5c0f\u96e8",
            "301":"\u5c0f\u96e8",
            "302":"\u5927\u96e8",
            "310":"\u5c0f\u96e8",
            "311":"\u5c0f\u96e8",
            "312":"\u5927\u96e8",
            "321":"\u9635\u96e8",
            "500":"\u5c0f\u96e8",
            "501":"\u4e2d\u96e8",
            "502":"\u5927\u96e8",
            "503":"\u5927\u96e8",
            "504":"\u66b4\u96e8",
            "511":"\u51bb\u96e8",
            "520":"\u9635\u96e8",
            "521":"\u9635\u96e8",
            "522":"\u5927\u96e8",
            "600":"\u5c0f\u96ea",
            "601":"\u96ea",
            "602":"\u5927\u96ea",
            "611":"\u96e8\u5939\u96ea",
            "621":"\u9635\u96ea",
            "701":"\u8584\u96fe",
            "711":"\u70df\u96fe",
            "721":"\u8584\u96fe",
            "731":"\u6c99\u65cb\u98ce",
            "741":"\u5927\u96fe",
            "800":"\u6674",
            "801":"\u6674\uff0c\u5c11\u4e91",
            "802":"\u591a\u4e91",
            "803":"\u591a\u4e91",
            "804":"\u9634\uff0c\u591a\u4e91",
            "900":"\u9f99\u5377\u98ce",
            "901":"\u70ed\u5e26\u98ce\u66b4",
            "902":"\u98d3\u98ce",
            "903":"\u51b7",
            "904":"\u70ed",
            "905":"\u5927\u98ce",
            "906":"\u51b0\u96f9",
            "950":"Setting",
            "951":"Calm",
            "952":"Light breeze",
            "953":"Gentle Breeze",
            "954":"Moderate breeze",
            "955":"Fresh Breeze",
            "956":"Strong breeze",
            "957":"High wind, near gale",
            "958":"Gale",
            "959":"Severe Gale",
            "960":"Storm",
            "961":"Violent Storm",
            "962":"Hurricane"
    }
},
    "cz":{
    "name":"Czech",
        "main":"",
        "description":{
        "200":"bou\u0159ka se slab\u00fdm de\u0161t\u011bm",
            "201":"bou\u0159ka a d\u00e9\u0161\u0165",
            "202":"bou\u0159ka se siln\u00fdm de\u0161t\u011bm",
            "210":"slab\u0161\u00ed bou\u0159ka",
            "211":"bou\u0159ka",
            "212":"siln\u00e1 bou\u0159ka",
            "221":"bou\u0159kov\u00e1 p\u0159eh\u00e1\u0148ka",
            "230":"bou\u0159ka se slab\u00fdm mrholen\u00edm",
            "231":"bou\u0159ka s mrholen\u00edm",
            "232":"bou\u0159ka se siln\u00fdm mrholen\u00edm",
            "300":"slab\u00e9 mrholen\u00ed",
            "301":"mrholen\u00ed",
            "302":"siln\u00e9 mrholen\u00ed",
            "310":"slab\u00e9 mrholen\u00ed a d\u00e9\u0161\u0165",
            "311":"mrholen\u00ed s de\u0161t\u011bm",
            "312":"siln\u00e9 mrholen\u00ed a d\u00e9\u0161\u0165",
            "313":"mrholen\u00ed a p\u0159eh\u00e1\u0148ky",
            "314":"mrholen\u00ed a siln\u00e9 p\u0159eh\u00e1\u0148ky",
            "321":"ob\u010dasn\u00e9 mrholen\u00ed",
            "500":"slab\u00fd d\u00e9\u0161\u0165",
            "501":"d\u00e9\u0161\u0165",
            "502":"prudk\u00fd d\u00e9\u0161\u0165",
            "503":"p\u0159\u00edvalov\u00fd d\u00e9\u0161\u0165",
            "504":"pr\u016ftr\u017e mra\u010den",
            "511":"mrznouc\u00ed d\u00e9\u0161\u0165",
            "520":"slab\u00e9 p\u0159eh\u00e1\u0148ky",
            "521":"p\u0159eh\u00e1\u0148ky",
            "522":"siln\u00e9 p\u0159eh\u00e1\u0148ky",
            "531":"ob\u010dasn\u00e9 p\u0159eh\u00e1\u0148ky",
            "600":"m\u00edrn\u00e9 sn\u011b\u017een\u00ed",
            "601":"sn\u011b\u017een\u00ed",
            "602":"hust\u00e9 sn\u011b\u017een\u00ed",
            "611":"zmrzl\u00fd d\u00e9\u0161\u0165",
            "612":"sm\u00ed\u0161en\u00e9 p\u0159eh\u00e1\u0148ky",
            "615":"slab\u00fd d\u00e9\u0161\u0165 se sn\u011bhem",
            "616":"d\u00e9\u0161\u0165 se sn\u011bhem",
            "620":"slab\u00e9 sn\u011bhov\u00e9 p\u0159eh\u00e1\u0148ky",
            "621":"sn\u011bhov\u00e9 p\u0159eh\u00e1\u0148ky",
            "622":"siln\u00e9 sn\u011bhov\u00e9 p\u0159eh\u00e1\u0148ky",
            "701":"mlha",
            "711":"kou\u0159",
            "721":"opar",
            "731":"p\u00edse\u010dn\u00e9 \u010di prachov\u00e9 v\u00edry",
            "741":"hust\u00e1 mlha",
            "751":"p\u00edsek",
            "761":"pra\u0161no",
            "762":"sope\u010dn\u00fd popel",
            "771":"prudk\u00e9 bou\u0159e",
            "781":"torn\u00e1do",
            "800":"jasno",
            "801":"skoro jasno",
            "802":"polojasno",
            "803":"obla\u010dno",
            "804":"zata\u017eeno",
            "900":"torn\u00e1do",
            "901":"tropick\u00e1 bou\u0159e",
            "902":"hurik\u00e1n",
            "903":"zima",
            "904":"horko",
            "905":"v\u011btrno",
            "906":"krupobit\u00ed",
            "950":"bezv\u011bt\u0159\u00ed",
            "951":"v\u00e1nek",
            "952":"v\u011bt\u0159\u00edk",
            "953":"slab\u00fd v\u00edtr",
            "954":"m\u00edrn\u00fd v\u00edtr",
            "955":"\u010derstv\u00fd v\u00edtr",
            "956":"siln\u00fd v\u00edtr",
            "957":"prudk\u00fd v\u00edtr",
            "958":"bou\u0159liv\u00fd v\u00edtr",
            "959":"vich\u0159ice",
            "960":"siln\u00e1 vich\u0159ice",
            "961":"mohutn\u00e1 vich\u0159ice",
            "962":"ork\u00e1n"
    }
},
    "kr":{
    "name":"Korea",
        "main":"",
        "description":{
        "200":"thunderstorm with light rain",
            "201":"thunderstorm with rain",
            "202":"thunderstorm with heavy rain",
            "210":"light thunderstorm",
            "211":"thunderstorm",
            "212":"heavy thunderstorm",
            "221":"ragged thunderstorm",
            "230":"thunderstorm with light drizzle",
            "231":"thunderstorm with drizzle",
            "232":"thunderstorm with heavy drizzle",
            "300":"light intensity drizzle",
            "301":"drizzle",
            "302":"heavy intensity drizzle",
            "310":"light intensity drizzle rain",
            "311":"drizzle rain",
            "312":"heavy intensity drizzle rain",
            "321":"shower drizzle",
            "500":"light rain",
            "501":"moderate rain",
            "502":"heavy intensity rain",
            "503":"very heavy rain",
            "504":"extreme rain",
            "511":"freezing rain",
            "520":"light intensity shower rain",
            "521":"shower rain",
            "522":"heavy intensity shower rain",
            "600":"light snow",
            "601":"snow",
            "602":"heavy snow",
            "611":"sleet",
            "621":"shower snow",
            "701":"mist",
            "711":"smoke",
            "721":"haze",
            "731":"sand\/dust whirls",
            "741":"fog",
            "800":"sky is clear",
            "801":"few clouds",
            "802":"scattered clouds",
            "803":"broken clouds",
            "804":"overcast clouds",
            "900":"tornado",
            "901":"tropical storm",
            "902":"hurricane",
            "903":"cold",
            "904":"hot",
            "905":"windy",
            "906":"hail",
            "950":"Setting",
            "951":"Calm",
            "952":"Light breeze",
            "953":"Gentle Breeze",
            "954":"Moderate breeze",
            "955":"Fresh Breeze",
            "956":"Strong breeze",
            "957":"High wind, near gale",
            "958":"Gale",
            "959":"Severe Gale",
            "960":"Storm",
            "961":"Violent Storm",
            "962":"Hurricane"
    }
},
    "gl":{
    "name":"Galician",
        "main":"",
        "description":{
        "200":"tormenta el\u00e9ctrica con choiva lixeira",
            "201":"tormenta el\u00e9ctrica con choiva",
            "202":"tormenta el\u00e9ctrica con choiva intensa",
            "210":"tormenta el\u00e9ctrica lixeira",
            "211":"tormenta el\u00e9ctrica",
            "212":"tormenta el\u00e9ctrica forte",
            "221":"tormenta el\u00e9ctrica irregular",
            "230":"tormenta el\u00e9ctrica con orballo lixeiro",
            "231":"tormenta el\u00e9ctrica con orballo",
            "232":"tormenta el\u00e9ctrica con orballo intenso",
            "300":"orballo lixeiro",
            "301":"orballo",
            "302":"orballo de gran intensidade",
            "310":"choiva e orballo lixeiro",
            "311":"choiva e orballo",
            "312":"choiva e orballo de gran intensidade",
            "321":"orballo de ducha",
            "500":"choiva lixeira",
            "501":"choiva moderada",
            "502":"choiva de gran intensidade",
            "503":"choiva moi forte",
            "504":"choiva extrema",
            "511":"choiva xeada",
            "520":"choiva de ducha de baixa intensidade",
            "521":"choiva de ducha",
            "522":"choiva de ducha de gran intensidade",
            "600":"nevada lixeira",
            "601":"neve",
            "602":"nevada intensa",
            "611":"auganeve",
            "621":"neve de ducha",
            "701":"n\u00e9boa",
            "711":"fume",
            "721":"n\u00e9boa lixeira",
            "731":"remui\u00f1os de area e polvo",
            "741":"bruma",
            "800":"ceo claro",
            "801":"algo de nubes",
            "802":"nubes dispersas",
            "803":"nubes rotas",
            "804":"nubes",
            "900":"tornado",
            "901":"tormenta tropical",
            "902":"furac\u00e1n",
            "903":"fr\u00edo",
            "904":"calor",
            "905":"ventoso",
            "906":"sarabia",
            "951":"calma",
            "952":"Vento frouxo",
            "953":"Vento suave",
            "954":"Vento moderado",
            "955":"Brisa",
            "956":"Vento forte",
            "957":"Vento forte, pr\u00f3ximo a vendaval",
            "958":"Vendaval",
            "959":"Vendaval forte",
            "960":"Tempestade",
            "961":"Tempestade violenta",
            "962":"Furac\u00e1n"
    }
},
    "vi":{
    "name":"vietnamese",
        "main":"",
        "description":{
        "200":"Gi\u00f4ng b\u00e3o v\u00e0 M\u01b0a nh\u1eb9",
            "201":"Gi\u00f4ng b\u00e3o v\u00e0 M\u01b0a",
            "202":"Gi\u00f4ng b\u00e3o M\u01b0a l\u1edbn",
            "210":"Gi\u00f4ng b\u00e3o c\u00f3 ch\u1edbp gi\u1eadt",
            "211":"B\u00e3o",
            "212":"Gi\u00f4ng b\u00e3o l\u1edbn",
            "221":"B\u00e3o v\u00e0i n\u01a1i",
            "230":"Gi\u00f4ng b\u00e3o v\u00e0 M\u01b0a ph\u00f9n nh\u1eb9",
            "231":"Gi\u00f4ng b\u00e3o v\u1edbi m\u01b0a ph\u00f9n",
            "232":"Gi\u00f4ng b\u00e3o v\u1edbi m\u01b0a ph\u00f9n n\u1eb7ng",
            "300":"\u00e1nh s\u00e1ng c\u01b0\u1eddng \u0111\u1ed9 m\u01b0a ph\u00f9n",
            "301":"m\u01b0a ph\u00f9n",
            "302":"m\u01b0a ph\u00f9n c\u01b0\u1eddng \u0111\u1ed9 n\u1eb7ng",
            "310":"m\u01b0a ph\u00f9n nh\u1eb9",
            "311":"m\u01b0a v\u00e0 m\u01b0a ph\u00f9n",
            "312":"m\u01b0a v\u00e0 m\u01b0a ph\u00f9n n\u1eb7ng",
            "321":"m\u01b0a r\u00e0o v\u00e0 m\u01b0a ph\u00f9n",
            "500":"m\u01b0a nh\u1eb9",
            "501":"m\u01b0a v\u1eeba",
            "502":"m\u01b0a c\u01b0\u1eddng \u0111\u1ed9 n\u1eb7ng",
            "503":"m\u01b0a r\u1ea5t n\u1eb7ng",
            "504":"m\u01b0a l\u1ed1c",
            "511":"m\u01b0a l\u1ea1nh",
            "520":"m\u01b0a r\u00e0o nh\u1eb9",
            "521":"m\u01b0a r\u00e0o",
            "522":"m\u01b0a r\u00e0o c\u01b0\u1eddng \u0111\u1ed9 n\u1eb7ng",
            "600":"tuy\u1ebft r\u01a1i nh\u1eb9",
            "601":"tuy\u1ebft",
            "602":"tuy\u1ebft n\u1eb7ng h\u1ea1t",
            "611":"m\u01b0a \u0111\u00e1",
            "621":"tuy\u1ebft m\u00f9 tr\u1eddi",
            "701":"s\u01b0\u01a1ng m\u1edd",
            "711":"kh\u00f3i b\u1ee5i",
            "721":"\u0111\u00e1m m\u00e2y",
            "731":"b\u00e3o c\u00e1t v\u00e0 l\u1ed1c xo\u00e1y",
            "741":"s\u01b0\u01a1ng m\u00f9",
            "800":"b\u1ea7u tr\u1eddi quang \u0111\u00e3ng",
            "801":"m\u00e2y th\u01b0a",
            "802":"m\u00e2y r\u1ea3i r\u00e1c",
            "803":"m\u00e2y c\u1ee5m",
            "804":"m\u00e2y \u0111en u \u00e1m",
            "900":"l\u1ed1c xo\u00e1y",
            "901":"c\u01a1n b\u00e3o nhi\u1ec7t \u0111\u1edbi",
            "902":"b\u00e3o l\u1ed1c",
            "903":"l\u1ea1nh",
            "904":"n\u00f3ng",
            "905":"gi\u00f3",
            "906":"m\u01b0a \u0111\u00e1",
            "950":"Ch\u1ebf \u0111\u1ecd",
            "951":"Nh\u1eb9 nh\u00e0ng",
            "952":"\u00c1nh s\u00e1ng nh\u1eb9",
            "953":"G\u00edo tho\u1ea3ng",
            "954":"Gi\u00f3 nh\u1eb9",
            "955":"Gi\u00f3 v\u1eeba ph\u1ea3i",
            "956":"Gi\u00f3 m\u1ea1nh",
            "957":"Gi\u00f3 xo\u00e1y",
            "958":"L\u1ed1c xo\u00e1y",
            "959":"L\u1ed1c xo\u00e1y n\u1eb7ng",
            "960":"B\u00e3o",
            "961":"B\u00e3o c\u1ea5p l\u1edbn",
            "962":"B\u00e3o l\u1ed1c"
    }
},
    "ar":{
    "name":"Arabic",
        "main":"",
        "description":{
        "200":"\u0639\u0627\u0635\u0641\u0629 \u0631\u0639\u062f\u064a\u0629 \u0645\u0639 \u0623\u0645\u0637\u0627\u0631 \u062e\u0641\u064a\u0641\u0629",
            "201":"\u0627\u0644\u0639\u0648\u0627\u0635\u0641 \u0631\u0639\u062f\u064a\u0629 \u0645\u0639 \u0627\u0644\u0645\u0637\u0631",
            "202":"\u0639\u0627\u0635\u0641\u0629 \u0631\u0639\u062f\u064a\u0629 \u0645\u0639 \u0627\u0645\u0637\u0627\u0631 \u063a\u0632\u064a\u0631\u0629",
            "210":"\u0639\u0627\u0635\u0641\u0629 \u0631\u0639\u062f\u064a\u0629 \u062e\u0641\u064a\u0641\u0629",
            "211":"\u0639\u0627\u0635\u0641\u0629 \u0631\u0639\u062f\u064a\u0629",
            "212":"\u0639\u0627\u0635\u0641\u0629 \u0631\u0639\u062f\u064a\u0629 \u062b\u0642\u064a\u0644\u0629",
            "221":"\u0639\u0627\u0635\u0641\u0629 \u0631\u0639\u062f\u064a\u0629 \u062e\u0634\u0646\u0629",
            "230":"\u0639\u0627\u0635\u0641\u0629 \u0631\u0639\u062f\u064a\u0629 \u0645\u0639 \u0631\u0630\u0627\u0630 \u062e\u0641\u064a\u0641",
            "231":"\u0639\u0627\u0635\u0641\u0629 \u0631\u0639\u062f\u064a\u0629 \u0645\u0639 \u0631\u0630\u0627\u0630",
            "232":"\u0639\u0627\u0635\u0641\u0629 \u0631\u0639\u062f\u064a\u0629 \u0645\u0639 \u0631\u0630\u0627\u0630 \u063a\u0632\u064a\u0631\u0629",
            "300":"\u0631\u0630\u0627\u0630 \u062e\u0641\u064a\u0641 \u0627\u0644\u0643\u062b\u0627\u0641\u0629",
            "301":"\u0631\u0630\u0627\u0630",
            "302":"\u0631\u0630\u0627\u0630 \u0634\u062f\u064a\u062f \u0627\u0644\u0643\u062b\u0627\u0641\u0629",
            "310":"\u0631\u0630\u0627\u0630 \u0645\u0637\u0631 \u062e\u0641\u064a\u0641 \u0627\u0644\u0643\u062b\u0627\u0641\u0629",
            "311":"\u0631\u0630\u0627\u0630 \u0645\u0637\u0631",
            "312":"\u0631\u0630\u0627\u0630 \u0645\u0637\u0631 \u0634\u062f\u064a\u062f \u0627\u0644\u0643\u062b\u0627\u0641\u0629",
            "321":"\u0648\u0627\u0628\u0644 \u0631\u0630\u0627\u0630",
            "500":"\u0645\u0637\u0631 \u062e\u0641\u064a\u0641",
            "501":"\u0645\u0637\u0631 \u0645\u062a\u0648\u0633\u0637 \u0627\u0644\u063a\u0632\u0627\u0631\u0629",
            "502":"\u0645\u0637\u0631 \u063a\u0632\u064a\u0631",
            "503":"\u0645\u0637\u0631 \u0634\u062f\u064a\u062f \u0627\u0644\u063a\u0632\u0627\u0631\u0629",
            "504":"\u0645\u0637\u0631 \u0639\u0627\u0644\u064a \u0627\u0644\u063a\u0632\u0627\u0631\u0629",
            "511":"\u0645\u0637\u0631 \u0628\u0631\u062f",
            "520":"\u0648\u0627\u0628\u0644 \u0645\u0637\u0631 \u062e\u0641\u064a\u0641",
            "521":"\u0648\u0627\u0628\u0644 \u0645\u0637\u0631",
            "522":"\u0648\u0627\u0628\u0644 \u0645\u0637\u0631 \u0634\u062f\u064a\u062f \u0627\u0644\u0643\u062b\u0627\u0641\u0629",
            "600":"\u062b\u0644\u0648\u062c \u062e\u0641\u064a\u0641\u0647",
            "601":"\u062b\u0644\u0648\u062c",
            "602":"\u062b\u0644\u0648\u062c \u0642\u0648\u064a\u0629",
            "611":"\u0635\u0642\u064a\u0639",
            "621":"\u0648\u0627\u0628\u0644 \u062b\u0644\u0648\u062c",
            "701":"\u0636\u0628\u0627\u0628",
            "711":"\u062f\u062e\u0627\u0646",
            "721":"\u063a\u064a\u0648\u0645",
            "731":"\u063a\u0628\u0627\u0631",
            "741":"\u063a\u064a\u0645",
            "800":"\u0633\u0645\u0627\u0621 \u0635\u0627\u0641\u064a\u0629",
            "801":"\u063a\u0627\u0626\u0645 \u062c\u0632\u0626",
            "802":"\u063a\u064a\u0648\u0645 \u0645\u062a\u0641\u0631\u0642\u0629",
            "803":"\u063a\u064a\u0648\u0645 \u0645\u062a\u0646\u0627\u062b\u0631\u0647",
            "804":"\u063a\u064a\u0648\u0645 \u0642\u0627\u062a\u0645\u0629",
            "900":"\u0625\u0639\u0635\u0627\u0631",
            "901":"\u0639\u0627\u0635\u0641\u0629 \u0627\u0633\u062a\u0648\u0627\u0626\u064a\u0629",
            "902":"\u0632\u0648\u064a\u0639\u0629",
            "903":"\u0628\u0627\u0631\u062f",
            "904":"\u062d\u0627\u0631",
            "905":"\u0631\u064a\u0627\u062d",
            "906":"\u0648\u0627\u0628\u0644",
            "950":"\u0625\u0639\u062f\u0627\u062f",
            "951":"\u0647\u0627\u062f\u0626",
            "952":"\u0646\u0633\u064a\u0645 \u062e\u0641\u064a\u0641",
            "953":"\u0646\u0633\u064a\u0645 \u0644\u0637\u064a\u0641",
            "954":"\u0646\u0633\u064a\u0645 \u0645\u0639\u062a\u062f\u0644",
            "955":"\u0646\u0633\u064a\u0645 \u0639\u0644\u064a\u0644",
            "956":"\u0646\u0633\u064a\u0645 \u0642\u0648\u064a",
            "957":"\u0631\u064a\u0627\u062d \u0642\u0648\u064a\u0629",
            "958":"\u0639\u0627\u0635\u0641",
            "959":"\u0639\u0627\u0635\u0641\u0629 \u0634\u062f\u064a\u062f\u0629",
            "960":"\u0639\u0627\u0635\u0641\u0629",
            "961":"\u0639\u0627\u0635\u0641\u0629 \u0639\u0646\u064a\u0641\u0629",
            "962":"\u0625\u0639\u0635\u0627\u0631"
    }
},
    "mk":{
    "name":"Macedonian",
        "main":"",
        "description":{
        "200":"\u0433\u0440\u043c\u0435\u0436\u0438 \u0441\u043e \u0441\u043b\u0430\u0431 \u0434\u043e\u0436\u0434",
            "201":"\u0433\u0440\u043c\u0435\u0436\u0438 \u0441\u043e \u0434\u043e\u0436\u0434",
            "202":"\u0433\u0440\u043c\u0435\u0436\u0438 \u0441\u043e \u0441\u0438\u043b\u0435\u043d \u0434\u043e\u0436\u0434",
            "210":"\u0441\u043b\u0430\u0431\u0438 \u0433\u0440\u043c\u0435\u0436\u0438",
            "211":"\u0433\u0440\u043c\u0435\u0436\u0438",
            "212":"\u0441\u0438\u043b\u043d\u0438 \u0433\u0440\u043c\u0435\u0436\u0438",
            "221":"\u043c\u043d\u043e\u0433\u0443 \u0441\u0438\u043b\u043d\u0438 \u0433\u0440\u043c\u0435\u0436\u0438",
            "230":"\u0433\u0440\u043c\u0435\u0436\u0438 \u0441\u043e \u0441\u043b\u0430\u0431\u043e \u0440\u043e\u0441\u0435\u045a\u0435",
            "231":"\u0433\u0440\u043c\u0435\u0436\u0438 \u0441\u043e \u0440\u043e\u0441\u0435\u045a\u0435",
            "232":"\u0433\u0440\u043c\u0435\u0436\u0438 \u0441\u043e \u0441\u0438\u043b\u043d\u043e \u0440\u043e\u0441\u0435\u045a\u0435",
            "300":"\u0441\u043b\u0430\u0431\u043e \u0440\u043e\u0441\u0435\u045a\u0435",
            "301":"\u0440\u043e\u0441\u0435\u045a\u0435",
            "302":"\u0441\u0438\u043b\u043d\u043e \u0440\u043e\u0441\u0435\u045a\u0435",
            "310":"\u0441\u043b\u0430\u0431\u043e \u0440\u043e\u0441\u0435\u045a\u0435",
            "311":"\u0440\u043e\u0441\u0435\u045a\u0435",
            "312":"\u0441\u0438\u043b\u043d\u043e \u0440\u043e\u0441\u0435\u045a\u0435",
            "321":"\u0434\u043e\u0436\u0434",
            "500":"\u0441\u043b\u0430\u0431 \u0434\u043e\u0436\u0434",
            "501":"\u0441\u043b\u0430\u0431 \u0434\u043e\u0436\u0434",
            "502":"\u0441\u0438\u043b\u0435\u043d \u0434\u043e\u0436\u0434",
            "503":"\u043c\u043d\u043e\u0433\u0443 \u0441\u0438\u043b\u0435\u043d \u0434\u043e\u0436\u0434",
            "504":"\u043e\u0431\u0438\u043b\u0435\u043d \u0434\u043e\u0436\u0434",
            "511":"\u0433\u0440\u0430\u0434",
            "520":"\u0441\u043b\u0430\u0431\u043e \u0440\u043e\u0441\u0435\u045a\u0435",
            "521":"\u0440\u043e\u0441\u0438",
            "522":"\u0441\u0438\u043b\u043d\u043e \u0440\u043e\u0441\u0435\u045a\u0435",
            "600":"\u0441\u043b\u0430\u0431 \u0441\u043d\u0435\u0433",
            "601":"\u0441\u043d\u0435\u0433",
            "602":"\u0441\u0438\u043b\u0435\u043d \u0441\u043d\u0435\u0433",
            "611":"\u043b\u0430\u043f\u0430\u0432\u0438\u0446\u0430",
            "621":"\u043b\u0430\u043f\u0430\u0432\u0438\u0446\u0430",
            "701":"\u043c\u0430\u0433\u043b\u0430",
            "711":"\u0441\u043c\u043e\u0433",
            "721":"\u0437\u0430\u043c\u0430\u0433\u043b\u0435\u043d\u043e\u0441\u0442",
            "731":"\u043f\u0435\u0441\u043e\u0447\u0435\u043d \u0432\u0440\u0442\u043b\u043e\u0433",
            "741":"\u043c\u0430\u0433\u043b\u0430",
            "800":"\u0447\u0438\u0441\u0442\u043e \u043d\u0435\u0431\u043e",
            "801":"\u043d\u0435\u043a\u043e\u043b\u043a\u0443 \u043e\u0431\u043b\u0430\u0446\u0438",
            "802":"\u043e\u0434\u0432\u043e\u0435\u043d\u0438 \u043e\u0431\u043b\u0430\u0446\u0438",
            "803":"\u043e\u0431\u043b\u0430\u0446\u0438",
            "804":"\u043e\u0431\u043b\u0430\u0447\u043d\u043e",
            "900":"\u0442\u043e\u0440\u043d\u0430\u0434\u043e",
            "901":"\u0442\u0440\u043e\u043f\u0441\u043a\u0430 \u0431\u0443\u0440\u0430",
            "902":"\u0443\u0440\u0430\u0433\u0430\u043d",
            "903":"\u043b\u0430\u0434\u043d\u043e",
            "904":"\u0442\u043e\u043f\u043b\u043e",
            "905":"\u0432\u0435\u0442\u0440\u043e\u0432\u0438\u0442\u043e",
            "906":"\u0433\u0440\u0430\u0434",
            "950":"\u0417\u0430\u043b\u0435\u0437",
            "951":"\u041c\u0438\u0440\u043d\u043e",
            "952":"\u0421\u043b\u0430\u0431 \u0432\u0435\u0442\u0430\u0440",
            "953":"\u0421\u043b\u0430\u0431 \u0432\u0435\u0442\u0430\u0440",
            "954":"\u0412\u0435\u0442\u0430\u0440",
            "955":"\u0421\u0432\u0435\u0436 \u0432\u0435\u0442\u0430\u0440",
            "956":"\u0421\u0438\u043b\u0435\u043d \u0432\u0435\u0442\u0430\u0440",
            "957":"\u041c\u043d\u043e\u0433\u0443 \u0441\u0438\u043b\u0435\u043d \u0432\u0435\u0442\u0430\u0440",
            "958":"\u041d\u0435\u0432\u0440\u0435\u043c\u0435",
            "959":"\u0421\u0438\u043b\u043d\u043e \u043d\u0435\u0432\u0440\u0435\u043c\u0435",
            "960":"\u0411\u0443\u0440\u0430",
            "961":"\u0421\u0438\u043b\u043d\u0430 \u0431\u0443\u0440\u0430",
            "962":"\u0423\u0440\u0430\u0433\u0430\u043d"
    }
},
    "sk":{
    "name":"Slovak",
        "main":"",
        "description":{
        "200":"b\u00farka so slab\u00fdm da\u017e\u010fom",
            "201":"b\u00farka s da\u017e\u010fom",
            "202":"b\u00farka so siln\u00fdm da\u017e\u010fom",
            "210":"mierna b\u00farka",
            "211":"b\u00farka",
            "212":"siln\u00e1 b\u00farka",
            "221":"prudk\u00e1 b\u00farka",
            "230":"b\u00farka so slab\u00fdm mrholen\u00edm",
            "231":"b\u00farka s mrholen\u00edm",
            "232":"b\u00farka so siln\u00fdm mrholen\u00edm",
            "300":"slab\u00e9 mrholenie",
            "301":"mrholenie",
            "302":"siln\u00e9 mrholenie",
            "310":"slab\u00e9 pop\u0155chanie",
            "311":"pop\u0155chanie",
            "312":"siln\u00e9 pop\u0155chanie",
            "321":"jemn\u00e9 mrholenie",
            "500":"slab\u00fd d\u00e1\u017e\u010f",
            "501":"mierny d\u00e1\u017e\u010f",
            "502":"siln\u00fd d\u00e1\u017e\u010f",
            "503":"ve\u013emi siln\u00fd d\u00e1\u017e\u010f",
            "504":"extr\u00e9mny d\u00e1\u017e\u010f",
            "511":"mrzn\u00faci d\u00e1\u017e\u010f",
            "520":"slab\u00e1 preh\u00e1nka",
            "521":"preh\u00e1nka",
            "522":"siln\u00e1 preh\u00e1nka",
            "600":"slab\u00e9 sne\u017eenie",
            "601":"sne\u017eenie",
            "602":"siln\u00e9 sne\u017eenie",
            "611":"d\u00e1\u017e\u010f so snehom",
            "621":"snehov\u00e1 preh\u00e1nka",
            "701":"hmla",
            "711":"dym",
            "721":"opar",
            "731":"pieskov\u00e9\/pra\u0161n\u00e9 v\u00edry",
            "741":"hmla",
            "800":"jasn\u00e1 obloha",
            "801":"takmer jasno",
            "802":"polojasno",
            "803":"obla\u010dno",
            "804":"zamra\u010den\u00e9",
            "900":"torn\u00e1do",
            "901":"tropick\u00e1 b\u00farka",
            "902":"hurik\u00e1n",
            "903":"zima",
            "904":"hor\u00faco",
            "905":"veterno",
            "906":"krupobitie",
            "950":"Nastavenie",
            "951":"Bezvetrie",
            "952":"Slab\u00fd v\u00e1nok",
            "953":"Jemn\u00fd vietor",
            "954":"Stredn\u00fd vietor",
            "955":"\u010cerstv\u00fd vietor",
            "956":"Siln\u00fd vietor",
            "957":"Siln\u00fd vietor, takmer v\u00edchrica",
            "958":"V\u00edchrica",
            "959":"Siln\u00e1 v\u00edchrica",
            "960":"B\u00farka",
            "961":"Ni\u010div\u00e1 b\u00farka",
            "962":"Hurik\u00e1n"
    }
},
    "hu":{
    "name":"Hungarian",
        "main":"",
        "description":{
        "200":"vihar enyhe es\u0151vel",
            "201":"vihar es\u0151vel",
            "202":"vihar heves es\u0151vel",
            "210":"enyhe zivatar",
            "211":"vihar",
            "212":"heves vihar",
            "221":"durva vihar",
            "230":"vihar enyhe szit\u00e1l\u00e1ssal",
            "231":"vihar szit\u00e1l\u00e1ssal",
            "232":"vihar er\u0151s szit\u00e1l\u00e1ssal",
            "300":"enyhe intenzit\u00e1s\u00fa szit\u00e1l\u00e1s",
            "301":"szit\u00e1l\u00e1s",
            "302":"er\u0151s intenzit\u00e1s\u00fa szit\u00e1l\u00e1s",
            "310":"enyhe intenzit\u00e1s\u00fa szit\u00e1l\u00f3 es\u0151",
            "311":"szit\u00e1l\u00f3 es\u0151",
            "312":"er\u0151s intenzit\u00e1s\u00fa szit\u00e1l\u00f3 es\u0151",
            "321":"z\u00e1por",
            "500":"enyhe es\u0151",
            "501":"k\u00f6zepes es\u0151",
            "502":"heves intenzit\u00e1s\u00fa es\u0151",
            "503":"nagyon heves es\u0151",
            "504":"extr\u00e9m es\u0151",
            "511":"\u00f3nos es\u0151",
            "520":"enyhe intenzit\u00e1s\u00fa z\u00e1por",
            "521":"z\u00e1por",
            "522":"er\u0151s intenzit\u00e1s\u00fa z\u00e1por",
            "600":"enyhe havaz\u00e1s",
            "601":"havaz\u00e1s",
            "602":"er\u0151s havaz\u00e1s",
            "611":"havas es\u0151",
            "621":"h\u00f3z\u00e1por",
            "701":"gyenge k\u00f6d",
            "711":"k\u00f6d",
            "721":"k\u00f6d",
            "731":"homokvihar",
            "741":"k\u00f6d",
            "800":"tiszta \u00e9gbolt",
            "801":"kev\u00e9s felh\u0151",
            "802":"sz\u00f3rv\u00e1nyos felh\u0151zet",
            "803":"er\u0151s felh\u0151zet",
            "804":"bor\u00fas \u00e9gbolt",
            "900":"torn\u00e1d\u00f3",
            "901":"tr\u00f3pusi vihar",
            "902":"hurrik\u00e1n",
            "903":"h\u0171v\u00f6s",
            "904":"forr\u00f3",
            "905":"szeles",
            "906":"j\u00e9ges\u0151",
            "950":"Nyugodt",
            "951":"Csendes",
            "952":"Enyhe szell\u0151",
            "953":"Finom szell\u0151",
            "954":"K\u00f6zepes sz\u00e9l",
            "955":"\u00c9l\u00e9nk sz\u00e9l",
            "956":"Er\u0151s sz\u00e9l",
            "957":"Er\u0151s, m\u00e1r viharos sz\u00e9l",
            "958":"Viharos sz\u00e9l",
            "959":"Er\u0151sen viharos sz\u00e9l",
            "960":"Sz\u00e9lvihar",
            "961":"Tombol\u00f3 sz\u00e9lvihar",
            "962":"Hurrik\u00e1n"
    }
},
    "ca":{
    "name":"Catalan",
        "main":"",
        "description":{
        "200":"Tempesta amb pluja suau",
            "201":"Tempesta amb pluja",
            "202":"Tempesta amb pluja intensa",
            "210":"Tempesta suau",
            "211":"Tempesta",
            "212":"Tempesta forta",
            "221":"Tempesta irregular",
            "230":"Tempesta amb plugim suau",
            "231":"Tempesta amb plugin",
            "232":"Tempesta amb molt plugim",
            "300":"Plugim suau",
            "301":"Plugim",
            "302":"Plugim intens",
            "310":"Plugim suau",
            "311":"Plugim",
            "312":"Plugim intens",
            "313":"Pluja",
            "314":"Pluja intensa",
            "321":"Plugim",
            "500":"Pluja suau",
            "501":"Pluja",
            "502":"Pluja intensa",
            "503":"Pluja molt intensa",
            "504":"Pluja extrema",
            "511":"Pluja gla\u00e7ada",
            "520":"Pluja suau",
            "521":"Pluja suau",
            "522":"Pluja intensa",
            "531":"Pluja irregular",
            "600":"Nevada suau",
            "601":"Nevada",
            "602":"Nevada intensa",
            "611":"Aiguaneu",
            "612":"Pluja d'aiguaneu",
            "615":"Plugim i neu",
            "616":"Pluja i neu",
            "620":"Nevada suau",
            "621":"Nevada",
            "622":"Nevada intensa",
            "701":"Boira",
            "711":"Fum",
            "721":"Boirina",
            "731":"Sorra",
            "741":"Boira",
            "751":"Sorra",
            "761":"Pols",
            "762":"Cendra volc\u00e0nica",
            "771":"X\u00e0fec",
            "781":"Tornado",
            "800":"Cel net",
            "801":"Lleugerament ennuvolat",
            "802":"N\u00favols dispersos",
            "803":"Nuvolositat variable",
            "804":"Ennuvolat",
            "900":"Tornado",
            "901":"Tempesta tropical",
            "902":"Hurac\u00e0",
            "903":"Fred",
            "904":"Calor",
            "905":"Vent",
            "906":"Pedra",
            "950":"",
            "951":"Calmat",
            "952":"Brisa suau",
            "953":"Brisa agradable",
            "954":"Brisa moderada",
            "955":"Brisa fresca",
            "956":"Brisca fora",
            "957":"Vent intens",
            "958":"Vendaval",
            "959":"Vendaval sever",
            "960":"Tempesta",
            "961":"Tempesta violenta",
            "962":"Hurac\u00e0"
    }
},
    "hr":{
    "name":"Croatian",
        "main":"",
        "description":{
        "200":"grmljavinska oluja s slabom ki\u0161om",
            "201":"grmljavinska oluja s ki\u0161om",
            "202":"grmljavinska oluja s jakom ki\u0161om",
            "210":"slaba grmljavinska oluja",
            "211":"grmljavinska oluja",
            "212":"jaka grmljavinska oluja",
            "221":"orkanska grmljavinska oluja",
            "230":"grmljavinska oluja sa slabom rosuljom",
            "231":"grmljavinska oluja s rosuljom",
            "232":"grmljavinska oluja sa jakom rosuljom",
            "300":"rosulja slabog intenziteta",
            "301":"rosulja",
            "302":"rosulja jakog intenziteta",
            "310":"rosulja s ki\u0161om slabog intenziteta",
            "311":"rosulja s ki\u0161om",
            "312":"rosulja s ki\u0161om jakog intenziteta",
            "313":"pljuskovi i rosulja",
            "314":"rosulja s jakim pljuskovima",
            "321":"rosulja s pljuskovima",
            "500":"slaba ki\u0161a",
            "501":"umjerena ki\u0161a",
            "502":"ki\u0161a jakog intenziteta",
            "503":"vrlo jaka ki\u0161a",
            "504":"ekstremna ki\u0161a",
            "511":"ledena ki\u0161a",
            "520":"pljusak slabog intenziteta",
            "521":"pljusak",
            "522":"pljusak jakog intenziteta",
            "531":"olujna ki\u0161a s pljuskovima",
            "600":"slabi snijeg",
            "601":"snijeg",
            "602":"gusti snijeg",
            "611":"susnje\u017eica",
            "612":"susnje\u017eica s pljuskovima",
            "615":"slaba ki\u0161a i snijeg",
            "616":"ki\u0161a i snijeg",
            "620":"snijeg s povremenim pljuskovima",
            "621":"snijeg s pljuskovima",
            "622":"snijeg s jakim pljuskovima",
            "701":"sumaglica",
            "711":"dim",
            "721":"izmaglica",
            "731":"kovitlaci pijeska ili pra\u0161ine",
            "741":"magla",
            "751":"pijesak",
            "761":"pra\u0161ina",
            "762":"vulkanski pepeo",
            "771":"zapusi vjetra s ki\u0161om",
            "781":"tornado",
            "800":"vedro",
            "801":"blaga naoblaka",
            "802":"ra\u0161trkani oblaci",
            "803":"isprekidani oblaci",
            "804":"obla\u010dno",
            "900":"tornado",
            "901":"tropska oluja",
            "902":"orkan",
            "903":"hladno",
            "904":"vru\u0107e",
            "905":"vjetrovito",
            "906":"tu\u010da",
            "950":"",
            "951":"lahor",
            "952":"povjetarac",
            "953":"slab vjetar",
            "954":"umjeren vjetar",
            "955":"umjereno jak vjetar",
            "956":"jak vjetar",
            "957":"\u017eestok vjetar",
            "958":"olujni vjetar",
            "959":"jak olujni vjetar",
            "960":"orkanski vjetar",
            "961":"jak orkanski vjetar",
            "962":"orkan"
    }
},
    "blank":{
    "name":"Catalan",
        "main":"",
        "description":{
        "200":"",
            "201":"",
            "202":"",
            "210":"",
            "211":"",
            "212":"",
            "221":"",
            "230":"",
            "231":"",
            "232":"",
            "300":"",
            "301":"",
            "302":"",
            "310":"",
            "311":"",
            "312":"",
            "313":"",
            "314":"",
            "321":"",
            "500":"",
            "501":"",
            "502":"",
            "503":"",
            "504":"",
            "511":"",
            "520":"",
            "521":"",
            "522":"",
            "531":"",
            "600":"",
            "601":"",
            "602":"",
            "611":"",
            "612":"",
            "615":"",
            "616":"",
            "620":"",
            "621":"",
            "622":"",
            "701":"",
            "711":"",
            "721":"",
            "731":"",
            "741":"",
            "751":"",
            "761":"",
            "762":"",
            "771":"",
            "781":"",
            "800":"",
            "801":"",
            "802":"",
            "803":"",
            "804":"",
            "900":"",
            "901":"",
            "902":"",
            "903":"",
            "904":"",
            "905":"",
            "906":"",
            "950":"",
            "951":"",
            "952":"",
            "953":"",
            "954":"",
            "955":"",
            "956":"",
            "957":"",
            "958":"",
            "959":"",
            "960":"",
            "961":"",
            "962":""
    }
}
};

